/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { MenuItem, Select, CircularProgress } from "@mui/material";
import themeColors from "../../../../assets/stylesheets/_var.scss";
import "../stylesheets/addApi.scss";

export default function ServiceName({
  serviceName,
  loading,
  handleChange,
  selectedService
}) {
  const { t } = useTranslation();
  let content;

  if (loading) {
    content = <CircularProgress size={24} sx={{ marginLeft: "5px" }} />;
  } else if (serviceName.length > 0) {
    content = serviceName.map((service) => (
      <MenuItem
        key={service.api_service_id}
        style={{
          fontWeight: "500",
          fontSize: "16px",
          color: themeColors.primaryText,
          fontFamily: themeColors.fontQuickSand
        }}
        value={service.api_service_name}
      >
        {service.api_service_name}
      </MenuItem>
    ));
  } else {
    content = (
      <MenuItem
        style={{
          fontFamily: themeColors.fontQuickSand,
          fontSize: "16px",
          fontWeight: "400",
          lineHeight: "20.7px",
          color: themeColors.secondaryText
        }}
        disabled
      >
        {t("ManageApi.createAPi.serviceName.noService")}
      </MenuItem>
    );
  }

  let errorMessage;
  if (!loading && serviceName.length === 0) {
    errorMessage = (
      <p style={{ color: "red", marginTop: "5px", marginLeft: "5px" }}>
        {t("ManageApi.createAPi.serviceName.serviceError")}
      </p>
    );
  }

  return (
    <div className="form-group">
      <label htmlFor="selectService">
        {t("ManageApi.createAPi.serviceName.selectService")}
      </label>
      <Select
        labelId="selectService"
        id="selectService"
        value={loading ? "" : selectedService.api_service_name}
        style={{
          width: "100%",
          fontWeight: "500",
          fontSize: "16px",
          color: themeColors.primaryText,
          fontFamily: themeColors.fontQuickSand
        }}
        onChange={handleChange}
      >
        {content}
      </Select>
      {errorMessage}
    </div>
  );
}

ServiceName.propTypes = {
  serviceName: PropTypes.arrayOf(
    PropTypes.shape({
      company_type_id: PropTypes.number,
      api_service_id: PropTypes.number,
      api_service_name: PropTypes.string
    })
  ),
  loading: PropTypes.bool,
  handleChange: PropTypes.func,
  selectedService: PropTypes.shape({
    company_type_id: PropTypes.number,
    api_service_id: PropTypes.number,
    api_service_name: PropTypes.string
  })
};
