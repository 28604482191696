import PropTypes from "prop-types";
import "../assets/stylesheets/insights.scss";
import { useTranslation } from "react-i18next";
import {
  convertSecToHours,
  convertTime,
  convertToK,
  convertToPercent,
  insightsDiff
} from "../../../utils/dataFilter";

function InsightsData({ value, compareValue, title, isCompare }) {
  const { t } = useTranslation();
  let tempValue = value;
  let tempCompValue = compareValue ?? undefined;

  if (title === t("analyticsPage.averageTime")) {
    tempValue = convertTime(tempValue);
    if (isCompare) tempCompValue = convertTime(tempCompValue);
  } else if (
    title === t("analyticsPage.bounceRate") ||
    title === t("PMSPage.convRate")
  ) {
    tempValue = convertToPercent(tempValue);
    if (isCompare) tempCompValue = convertToPercent(tempCompValue);
  } else if (
    title === t("analyticsPage.revenue") ||
    title === t("PMSPage.revenue") ||
    title === t("PMSPage.revenuePerBooking")
  ) {
    tempValue = `${convertToK(tempValue)} €`;
    if (isCompare) tempCompValue = `${convertToK(tempCompValue)} €`;
  } else if (title === t("isiChatDashboard.total_spent_time")) {
    tempValue = convertSecToHours(tempValue);
    if (isCompare) tempCompValue = convertTime(tempCompValue);
  } else {
    tempValue = convertToK(value);
    if (isCompare) tempCompValue = convertToK(tempCompValue);
  }

  return (
    <div
      className={
        isCompare ? "flex-vertical " : "flex-vertical general-insights"
      }
    >
      {isCompare && <div className="insight-title">{title}</div>}
      <div className={isCompare ? "compare-insight-value" : "insight-value"}>
        {tempValue}
      </div>
      {isCompare && (
        <>
          <div className="insight-value">{tempCompValue}</div>
          {insightsDiff(value, compareValue)}
        </>
      )}
      {!isCompare && <div className="insight-title">{title}</div>}
    </div>
  );
}

export default function Insights({ insights, isCompare, insightsTitle }) {
  const { generalInsights, compareInsights } = insights;
  const keys = Object.keys(insightsTitle);
  return (
    <div className="flex-between insight-box">
      {keys.map((insight, i) => (
        <>
          <InsightsData
            isCompare={isCompare}
            value={generalInsights[insight]}
            compareValue={compareInsights?.[insight]}
            title={insightsTitle[insight]}
          />
          {i === keys.length - 1 ? null : <div className="line-break" />}
        </>
      ))}
    </div>
  );
}

InsightsData.propTypes = {
  value: PropTypes.number,
  compareValue: PropTypes.number,
  title: PropTypes.string,
  isCompare: PropTypes.bool
};

Insights.propTypes = {
  insights: PropTypes.oneOfType([PropTypes.object]).isRequired,
  insightsTitle: PropTypes.oneOfType([PropTypes.object]),
  isCompare: PropTypes.bool
};
