import PropTypes from "prop-types";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Bar, getElementsAtEvent } from "react-chartjs-2";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import {
  updateSelectedISIFilters,
  updateSelectedPMSFilters,
  updateSelectedPersonasFilters
} from "../../../../store/slices/generalSlice";

export default function MultiBarChart({
  data,
  options,
  filterTitle,
  dashboard,
  filterValues
}) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const chartRef = useRef();
  const dispatch = useDispatch();

  const onClick = (event) => {
    const clicked = getElementsAtEvent(chartRef.current, event);

    if (clicked.length > 0) {
      const clickedLabel = data.labels[clicked[0].index];
      const updatedFilters = { ...filterValues };

      if (filterValues[filterTitle]?.includes(clickedLabel)) {
        // Remove clicked label from filterValues
        const filteredValues = filterValues[filterTitle].filter(
          (value) => value !== clickedLabel
        );
        if (filteredValues.length > 1) {
          updatedFilters[filterTitle] = filteredValues;
        } else {
          // If only one value remains, remove the filter
          delete updatedFilters[filterTitle];
        }
      } else if (!filterValues[filterTitle] || event.metaKey || event.ctrlKey) {
        // Add clicked label to filterValues
        updatedFilters[filterTitle] = [
          ...(filterValues[filterTitle] ?? []),
          clickedLabel
        ];
      } else {
        // Replace filterValues with the clicked label
        updatedFilters[filterTitle] = [clickedLabel];
      }

      // Dispatch updates based on the dashboard
      if (dashboard === "isichat") {
        dispatch(updateSelectedISIFilters(updatedFilters));
      } else if (dashboard === "personas") {
        dispatch(updateSelectedPersonasFilters(updatedFilters));
      } else {
        dispatch(updateSelectedPMSFilters(updatedFilters));
      }
    }
  };

  return <Bar data={data} options={options} ref={chartRef} onClick={onClick} />;
}

MultiBarChart.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  filterTitle: PropTypes.string,
  options: PropTypes.oneOfType([PropTypes.object]).isRequired,
  filterValues: PropTypes.oneOfType([PropTypes.object]),
  dashboard: PropTypes.string
};
