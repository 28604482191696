import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import EditApi from "./components/EditApi";
import { Toastr } from "../../../components/Toastr/Toastr";
import { changePassword, updateManageApi } from "./api/editApi";
import { getToken } from "../AddApi/api/addApi";

export default function Index({
  setActiveManageComponent,
  selectedRowData,
  handleChnageRender
}) {
  const [showRegeneratePasswordModal, setShowRegeneratePasswordModal] =
    useState(false);

  const handleRegeneratePasswordClick = () => {
    setShowRegeneratePasswordModal(!showRegeneratePasswordModal);
  };

  const [regenaratePassword, setRegenaratePassword] = useState("");
  const [roomNumber, setRoomNumber] = useState(selectedRowData.roomNumber);
  const { websitesList, websiteID } = useSelector((state) => state.generalData);
  const userName = websitesList.find((ele) => ele.id === websiteID)?.name;
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  console.log(websiteID);

  const handleSave = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      if (selectedRowData.apiName === "ASA") {
        const apiResponse = await updateManageApi({
          room_number: Number(roomNumber),
          website_id: websiteID,
          api_service_id: selectedRowData.apiServiceId
        });

        if (apiResponse.data.result.success) {
          setLoading(false);
          Toastr.success(t("ManageApi.editApi.roomUpdateMessage"));
          setActiveManageComponent(0);
          handleChnageRender();
        }

        if (regenaratePassword) {
          const response = await changePassword({
            website_id: websiteID,
            password: regenaratePassword
          });
          if (response.data.status === "ok") {
            setLoading(false);
            Toastr.success(t("ManageApi.editApi.addSuccess"));
            setActiveManageComponent(0);
          }
        }
      } else {
        if (!regenaratePassword) {
          setLoading(false);
          Toastr.error(t("ManageApi.editApi.addError"));
          return;
        }
        const response = await changePassword({
          username: userName,
          password: regenaratePassword
        });
        if (response.data.status === "ok") {
          setLoading(false);
          Toastr.success(t("ManageApi.editApi.addSuccess"));
          setActiveManageComponent(0);
        }
      }
    } catch (error) {
      setLoading(false);
      Toastr.error(t("ManageApi.wrongMessage"));
    }
  };

  const regenarateToken = async (event) => {
    event.preventDefault();
    try {
      const response = await getToken({ websiteId: websiteID });
      if (response.data.status === "ok") {
        setToken(response.data.token);
        Toastr.success(t("ManageApi.editApi.tokenSuccess"));
      }
    } catch (error) {
      Toastr.error(t("ManageApi.editApi.tokenError"));
    }
  };

  return (
    <EditApi
      setActiveManageComponent={setActiveManageComponent}
      selectedRowData={selectedRowData}
      showRegeneratePasswordModal={showRegeneratePasswordModal}
      handleRegeneratePasswordClick={handleRegeneratePasswordClick}
      regenaratePassword={regenaratePassword}
      setRegenaratePassword={setRegenaratePassword}
      handleSave={handleSave}
      userName={userName}
      loading={loading}
      token={token}
      regenarateToken={regenarateToken}
      setRoomNumber={setRoomNumber}
      roomNumber={roomNumber}
    />
  );
}

Index.propTypes = {
  setActiveManageComponent: PropTypes.func,
  selectedRowData: PropTypes.shape({
    id: PropTypes.number,
    apiName: PropTypes.string,
    status: PropTypes.number,
    roomNumber: PropTypes.number,
    apiServiceId: PropTypes.number
  }),
  handleChnageRender: PropTypes.func
};
