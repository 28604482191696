/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable */
import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import ReactDOMServer from "react-dom/server";
import HeatMap from "@uiw/react-heat-map";
import themeColors from "../../../assets/stylesheets/_var.scss";

export default function HeatMapComponent({
  values,
  year,
  month,
  legendColors
}) {
  const startDate = new Date(`${year}/01/02`);
  const endDate = new Date(`${year}/12/31`);
  const { t } = useTranslation();
  const weekLabels = ["S", "M", "T", "W", "T", "F", "S"];

  const tooltipHeatmap = (data) => {
    return (
      <div>
        <div>
          {t("common.date")}: {data.date}
        </div>
        <div className="tooltip-heatmap">
          {t("heatmap.bookings")} <span>{data.booking || 0}</span>
          &nbsp;
          {data?.count === 0 ||
          data?.roomNumber === 0 ||
          data?.count === null ||
          data?.count === undefined ||
          data?.roomNumber === null ||
          data?.roomNumber === undefined
            ? ""
            : `(${data?.count}%)`}
        </div>
      </div>
    );
  };

  const getColor = (data) => {
    if (data?.roomNumber === 0) {
      return themeColors.primaryColorShade2;
    }
    const bookingPercentage = data?.booking || 0;

    if (bookingPercentage > 89 && bookingPercentage <= 100) {
      return legendColors[100];
    } else if (bookingPercentage > 59 && bookingPercentage <= 89) {
      return legendColors[89];
    } else if (bookingPercentage > 29 && bookingPercentage <= 59) {
      return legendColors[59];
    } else if (bookingPercentage > 0 && bookingPercentage <= 29) {
      return legendColors[29];
    } else {
      return legendColors[0];
    }
  };

  const customRect = (props, data) => {
    const dateInData = new Date(data.date);
    let opacity = dateInData.getMonth() === month - 1 ? 1 : 0.45;
    let stroke =
      dateInData.getMonth() === month - 1 ? themeColors.primaryColor : null;
    if (month === null) {
      opacity = 1;
      stroke = null;
    }
    return (
      <rect
        {...{ ...props, opacity, stroke, strokeWidth: 1 }}
        {...props}
        rx={4}
        fill={getColor(data)}
        data-tooltip-id="tooltip-for-worldmap"
        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
          tooltipHeatmap(data)
        )}
      />
    );
  };

  return (
    <HeatMap
      width={"1235px"}
      rectSize={19.75}
      className="heatmap-comp"
      value={[...values]}
      startDate={startDate}
      rectRender={(event, data) => customRect(event, data)}
      endDate={endDate}
      weekLabels={weekLabels}
      space={3}
      legendCellSize={0}
      panelColors={legendColors}
      monthLabels={[]}
    />
  );
}

HeatMapComponent.propTypes = {
  values: PropTypes.oneOfType([PropTypes.array]),
  year: PropTypes.number,
  month: PropTypes.number,
  legendColors: PropTypes.oneOfType([PropTypes.object])
};
