import { useSelector } from "react-redux";
import AuthorizedHeader from "../../components/layouts/AuthorizedHeader";
import ComingSoon from "../../features/Placeholders/ComingSoon";

export default function ManageCampaign() {
  const { websiteId } = useSelector((state) => state.generalData);
  console.log("websiteId====>", websiteId);

  return (
    <div>
      <AuthorizedHeader />
      <div className="container">
        <ComingSoon />
      </div>
    </div>
  );
}
