// NotificationBar.js
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import "./notification.scss";
import { useTranslation } from "react-i18next";
import mailUnread from "../../assets/icons/mailIcon.svg";
import mailRead from "../../assets/icons/mailRead.svg";
import closeIcon from "../../assets/icons/closeNoti.svg";
import axios from "../../api/axios";
import { updateScrollArchive } from "../../store/slices/generalSlice";
import notificationMapping from "../../utils/notificationMappings";
import getNotificationInfo from "../../utils/getNotificationInfo";
import formatElapsedTime from "../../utils/formatElapsedTime";

function NotificationBar({
  isOpen,
  setIsOpen,
  notification,
  setNotification,
  notificationApi
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const mappedNotifications = notificationMapping(t);

  const handleMarkRead = async (index) => {
    const temp = notification.map((ele, i) => {
      if (i === index) {
        return { ...ele, read: 1 };
      }
      return ele;
    });
    setNotification(temp);
    const dataToSend = temp.map(({ read, notificationId }) => ({
      read,
      notificationId
    }));
    await axios.post("/vivaWallet/notification-read", dataToSend);
    notificationApi();
  };

  const handleAllNotifications = async (temp) => {
    const dataToSend = temp.map(({ read, notificationId }) => ({
      read,
      notificationId
    }));
    await axios.post("/vivaWallet/notification-read", dataToSend);
  };

  const handleMarkAllRead = () => {
    const temp = notification.map((ele) => {
      return { ...ele, read: 1 };
    });
    handleAllNotifications(temp);
    setNotification([]);
    setTimeout(() => {
      notificationApi();
    }, [1000]);
  };

  const goToArchive = () => {
    navigate("/settings/notification");
    dispatch(updateScrollArchive(true));
  };

  return (
    <div className={`notification-bar ${isOpen ? "open" : ""}`}>
      <div className="flex-between notification-heading">
        <div className="header">{t("notification.notification")}</div>
        <div className="flex-center">
          <button
            type="button"
            className="mark-read"
            onClick={handleMarkAllRead}
          >
            {t("notification.markallasread")}
          </button>
          <img
            src={closeIcon}
            alt="closeNoti"
            className="closeNoti"
            onClick={() => setIsOpen(false)}
          />
        </div>
      </div>
      <div className="notification-list">
        {notification.map(
          (ele, i) =>
            ele.read !== 1 && (
              <div>
                <div className={`notification-item ${ele.read && "read"}`}>
                  <div className="flex">
                    {ele.read ? (
                      <img src={mailRead} alt="notificationIcon" />
                    ) : (
                      <img
                        src={mailUnread}
                        alt="notificationIcon"
                        onClick={() => handleMarkRead(i)}
                      />
                    )}
                    <div>
                      <div className="notificationText">
                        {
                          getNotificationInfo(
                            ele.notificationText,
                            mappedNotifications
                          ).text
                        }
                      </div>
                      <div className="notificationBody">
                        Go to{" "}
                        <span
                          className="link"
                          onClick={() =>
                            navigate(
                              getNotificationInfo(
                                ele.notificationText,
                                mappedNotifications
                              ).link
                            )
                          }
                        >
                          {
                            getNotificationInfo(
                              ele.notificationText,
                              mappedNotifications
                            ).body
                          }
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="notification-time">
                    {" "}
                    {formatElapsedTime(ele.time, t)}
                  </div>
                </div>
                <hr className="notification-bottom" />
              </div>
            )
        )}
      </div>{" "}
      <button type="button" className="clearAllButton" onClick={goToArchive}>
        {t("notification.gotoarchive")}
      </button>
    </div>
  );
}

NotificationBar.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.bool,
  notification: PropTypes.oneOfType([PropTypes.arrayOf]).isRequired,
  setNotification: PropTypes.oneOfType([PropTypes.arrayOf]).isRequired,
  notificationApi: PropTypes.func
};
export default NotificationBar;
