/* eslint-disable */
import React from "react";
import { useTranslation } from "react-i18next";
import themeColors from "../../assets/stylesheets/_var.scss";

export default function DragAndDropArea({
  width,
  height,
  handleClick,
  handleDrop,
  handleDragOver,
  handleFileUpload,
  folder,
  uploadedImages,
  text
}) {
  const { t } = useTranslation();

  return (
    <div>
      <div
        style={{
          cursor: "pointer",
          marginTop: "2%",
          paddingTop: "4%",
          paddingBottom: "4%",
          paddingLeft: "4%",
          backgroundColor: "rgba(249, 249, 249, 1)"
        }}
        className="background-box"
        onClick={handleClick}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <input
          id="fileInput"
          type="file"
          multiple
          accept="image/*" // Specify accepted file types here
          style={{
            display: "none"
          }}
          onChange={(e) => handleFileUpload(e?.target?.files)}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center"
          }}
        >
          <div>
            <img
              src={folder}
              style={{
                border: "none",
                width: width,
                height: height
              }}
            />
          </div>

          <div
            style={{
              paddingLeft: "2%"
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center"
              }}
            >
              <p
                style={{
                  textAlign: "center",
                  fontFamily: themeColors.fontQuickSand,
                  fontWeight: "700",
                  fontSize: "20px",
                  lineHeight: "26px"
                }}
              >
                {t("uploadImages.drag&Drop")}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "2%"
              }}
            >
              <p
                style={{
                  textAlign: "center",
                  fontFamily: themeColors.fontQuickSand,
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "20px",
                  textDecoration: "underline",
                  color: "rgba(86, 86, 86, 1)"
                }}
              >
                {t("uploadImages.selectFile")}
              </p>
            </div>

            {uploadedImages?.length > 0 && (
              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: "2%"
                }}
              >
                <p
                  style={{
                    fontFamily: themeColors.fontQuickSand,
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "20px",
                    color: "rgba(158, 172, 95, 1)"
                  }}
                >
                  {uploadedImages?.length} {text}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      {uploadedImages?.length > 0 ? (
        <div
          style={{
            paddingTop: "2%",
            fontFamily: themeColors.fontQuickSand,
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "20px",
            color: "rgba(118, 118, 118, 1)"
          }}
        >
          <p> {t("uploadImages.imagePreview")}</p>
        </div>
      ) : null}
    </div>
  );
}
