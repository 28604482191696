/* eslint-disable */
import React from "react";
import deletewhite from "../../assets/icons/deletewhite.png";
import themeColors from "../../assets/stylesheets/_var.scss";

export default function SelectedImage({
  uploadedImages,
  setUploadedImages,
  preViewedImage,
  setPreviewedImage
}) {
  const handleDeleteImage = (index) => {
    setUploadedImages((prevImages) =>
      prevImages?.filter((_, i) => i !== index)
    );
  };

  const handleImagePreview = (index) => {
    setPreviewedImage((prevIndex) => (prevIndex === index ? null : index));
  };

  // const imageLimitExceeded = uploadedImages?.some(
  //   (image) => image?.formattedSize > "0.5 MB"
  // );

  console.log("uploadedImages====>", uploadedImages);

  return (
    <div>
      <div
        style={{
          paddingTop: "1%",
          cursor: "pointer"
        }}
      >
        {uploadedImages?.map((image, index) => {
          // const sizeInMB = parseFloat(image?.formattedSize.split(" ")[0]);
          // const sizeInMB = image?.formattedSize;
          // console.log("sizeInMB====>", sizeInMB);
          return (
            <div
              key={index}
              onClick={() => handleImagePreview(index)}
              style={{
                paddingTop: "1%",
                marginLeft: "1%",
                paddingBottom: "1%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor:
                  preViewedImage === index
                    ? "rgba(232, 240, 197, 1)"
                    : "transparent"
              }}
            >
              {/* Uploaded image name */}
              <div
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: themeColors.fontQuickSand,
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "20px",
                  color: themeColors.graphAxisColor,
                  paddingLeft: "1%"
                }}
              >
                <p
                  style={{
                    textAlign: "center"
                  }}
                >
                  {image?.name}
                </p>

                {image.formattedSize > 30 && (
                  <span
                    style={{
                      fontFamily: themeColors.fontQuickSand,
                      color: "red",
                      marginLeft: "10px"
                    }}
                  >
                    * This image is greater than 1 MB
                  </span>
                )}
              </div>
              {/* Uploaded image name ends */}

              <div>
                <button
                  className="editButton"
                  type="button"
                  onClick={() => handleDeleteImage(index)}
                >
                  <img
                    style={{
                      width: "24px",
                      height: "24px"
                    }}
                    src={deletewhite}
                    alt="edit"
                  />
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
