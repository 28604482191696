import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { IconButton } from "@mui/material";
import SavedApiTable from "./components/SavedApiTable";
import StatusRed from "../../../assets/icons/status-red.svg";
import StatusGreen from "../../../assets/icons/status (3).svg";
import EditButton from "./assets/edit.svg";
import DeleteButton from "./assets/delete.svg";
import Sort from "../../../assets/icons/sort.svg";

function CustomButton() {
  return (
    <IconButton>
      <img src={Sort} alt="sort" />
    </IconButton>
  );
}

export default function Index({
  data,
  setActiveManageComponent,
  setSelectedRowData,
  setSelectMultipleRowData
}) {
  const { t } = useTranslation();
  const columns = [
    {
      field: "apiName",
      headerName: t("ManageApi.savedApis.dataTable.apiName"),
      flex: 4
    },
    {
      field: "status",
      headerName: t("ManageApi.savedApis.dataTable.status"),
      flex: 0,
      renderCell: (params) => (
        <div className="status-icon">
          {params.value === 1 ? (
            <img src={StatusGreen} alt="running" />
          ) : (
            <img src={StatusRed} alt="stopped" />
          )}
        </div>
      )
    },
    {
      field: "actions",
      headerName: t("ManageApi.savedApis.dataTable.actions"),
      flex: 0,
      renderCell: () => (
        <div className="button-group">
          <button
            className="delete-button"
            type="button"
            onClick={() => setActiveManageComponent(3)}
          >
            <img src={DeleteButton} alt="delete button" />
          </button>
          <button
            className="edit-button"
            type="button"
            onClick={() => setActiveManageComponent(2)}
          >
            <img src={EditButton} alt="edit button" />
          </button>
        </div>
      )
    }
  ];

  const rows = data.map((e) => ({
    id: e.id,
    apiName: e.api_service_name,
    status: e.status,
    roomNumber: e.room_number,
    apiServiceId: e.api_service_id
  }));

  const handleRowClick = (rowData) => {
    setSelectedRowData(rowData);
  };

  const handleRowSelect = (rowData) => {
    setSelectMultipleRowData(rowData);
  };

  return (
    <SavedApiTable
      columns={columns}
      rows={rows}
      CustomButton={CustomButton}
      handleRowClick={handleRowClick}
      handleRowSelect={handleRowSelect}
    />
  );
}

Index.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      apiName: PropTypes.string,
      status: PropTypes.number
    })
  ),
  setActiveManageComponent: PropTypes.func,
  setSelectedRowData: PropTypes.func,
  setSelectMultipleRowData: PropTypes.func
};
