/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import LocalStorage from "../../utils/LocalStorgae";
import AuthorizedHeader from "../../components/layouts/AuthorizedHeader";
import DateCalendar from "../../components/DateRange";
import closeButton from "../../assets/icons/Close.svg";
import "../stylesheets/dashboard.scss";
import {
  getAnalyticsPages,
  getCompareAnalyticsPages,
  getCompareDataItems,
  getDataItems
} from "../../store/apiSlice";
import {
  updateDevicesData,
  updateBrowsersData,
  updateCountriesData,
  updateFilteredData,
  updateInsights,
  updatePersonaData,
  updateWorldData,
  updateViewsVisitorsData,
  updatePageUrlData,
  updateSourceData,
  updateMediumData,
  updateCampaignData,
  updateTermData,
  updateContentData,
  updateCompareDevicesData,
  updateCompareBrowsersData,
  updateCompareCountriesData,
  updateCompareFilteredData,
  updateCompareInsights,
  updateComparePersonaData,
  updateCompareWorldData,
  updateCompareViewsVisitorsData,
  updateComparePageUrlData,
  updateShouldUpdateData,
  updateCompareShouldUpdateData,
  updateCompareSourceData,
  updateCompareMediumData,
  updateCompareCampaignData,
  updateCompareTermData,
  updateCompareContentData
} from "../../store/slices/dataSlice";

import { updateSelectedFilters } from "../../store/slices/generalSlice";
import FilterPill from "../../components/FilterPill";
import CompareDashboard from "../../features/DashBoard/GlobalDashboard/CompareDashboard";
import GeneralDashboard from "../../features/DashBoard/GlobalDashboard/GeneralDashboard";
import {
  dataFilterFunction,
  getGraphDataSorted,
  getInsights,
  getViewsVisitorsData,
  filterAccordingToSelected,
  compareAndMergeData,
  compareMergeViewsVisitors
} from "../../utils/dataFilter";
import {
  setCompareBrowsersData,
  setCompareCountriesData,
  setCompareDevicesData,
  setComparePageUrlData,
  setComparePersonaData,
  setCompareViewsVisitorsData,
  setCompareWorldData,
  setCompareSourceData,
  setCompareMediumData,
  setCompareCampaignData,
  setCompareTermData,
  setCompareContentData
} from "../../store/slices/compareSlice";
import storage from "../../utils/storage";
import Loading from "../../features/Placeholders/Loading";
import axios from "../../api/axios";

export default function Dashboard() {
  const dashboard = "analytics";
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const dataValues = {
    persona: "persona_label",
    date: "Date",
    device: "device",
    browser: "browser",
    country: "country",
    link: "current_link",
    source: "source",
    medium: "medium",
    campaign: "campaign",
    term: "term",
    content: "content"
  };

  const filterTranslation = {
    Browsers: t("analyticsPage.browsersTitle"),
    Devices: t("analyticsPage.devicesTitle"),
    Country: t("analyticsPage.countryTitle"),
    Persona: t("analyticsPage.personaTitle"),
    Pages: t("analyticsPage.pagesTitle"),
    World: t("analyticsPage.worldTitle"),
    Source: t("personaDashboard.source"),
    Medium: t("personaDashboard.medium"),
    Campaign: t("personaDashboard.campaign"),
    Term: t("personaDashboard.term"),
    Content: t("personaDashboard.content")
  };
  const insightsTitle = {
    visitors: t("analyticsPage.visitors"),
    views: t("analyticsPage.views"),
    actions: t("analyticsPage.actions"),
    avgTime: t("analyticsPage.averageTime"),
    bounceRate: t("analyticsPage.bounceRate"),
    tag_actions: t("analyticsPage.tagActions"),
    revenue: t("analyticsPage.revenue")
  };

  const storedFilter = JSON.parse(storage.getItem("selected"));

  const [bannerNotification, setBannerNotification] = useState([]);
  const [bannerDelete, setBannerDelete] = useState([]);
  const [bannerOpen, setBannerOpen] = useState(false);
  const [bodyParams, setBodyParams] = useState(null);
  const [bodyPagesParams, setBodyPagesParams] = useState(null);

  const {
    pageURLGraphData: pageURLGraphData1,
    shouldUpdateData,
    isLoading,
    graphSepData: graphSepData1,
    allData: allData1,
    devicesData: devicesData1,
    browsersData: browsersData1,
    countriesData: countriesData1,
    pageUrlData: pageUrlData1,
    viewsVisitorsData: viewsVisitorsData1,
    personaData: personaData1,
    filteredData: filteredData1,
    sourceData: sourceData1,
    mediumData: mediumData1,
    campaignData: campaignData1,
    termData: termData1,
    contentData: contentData1
  } = useSelector((state) => state.dashboardData);

  const {
    pageURLGraphData: pageURLGraphData2,
    graphSepData: graphSepData2,
    allData: allData2,
    devicesData: devicesData2,
    browsersData: browsersData2,
    countriesData: countriesData2,
    pageUrlData: pageUrlData2,
    viewsVisitorsData: viewsVisitorsData2,
    personaData: personaData2,
    filteredData: filteredData2,
    sourceData: sourceData2,
    mediumData: mediumData2,
    campaignData: campaignData2,
    termData: termData2,
    contentData: contentData2
  } = useSelector((state) => state.dataToCompare);

  const {
    websiteID,
    dateRange,
    compareDateRange,
    isCompare,
    timeType,
    selectedFilters,
    createdBy: customerId
  } = useSelector((state) => state.generalData);

  const language = LocalStorage.getItem("selectedLanguage");

  const notificationApi = async () => {
    try {
      const res = await axios.post("/vivaWallet/notification/", {
        customer_id: customerId
      });

      if (res.data.bannerNotification.length > 0) {
        setBannerNotification(res.data.bannerNotification[0]);
        setBannerOpen(true);
        setBannerDelete(res.data.bannerNotification[0].notificationId);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    notificationApi();
  }, []);

  const modifyPageURLData = (data) => {
    return {
      labels: data?.map((ele) => ele.label),
      links: data?.map((ele) => ele.wholeLink),
      dataset: data?.map((ele) => ele.visitors),
      visitors: data?.map((ele) => ele.visitors),
      views: data?.map((ele) => ele.views),
      bounce: data?.map((ele) => ele.bounce),
      preSales: data?.map((ele) => ele.preSales),
      sales: data?.map((ele) => ele.sales),
      revenue: data?.map((ele) => ele.revenue)
    };
  };

  const updatePages = () => {
    let graphData = pageURLGraphData1?.data;
    if (selectedFilters[filterTranslation.Pages]) {
      dispatch(
        updatePageUrlData({
          ...filterAccordingToSelected(
            { ...modifyPageURLData(graphData) },
            selectedFilters[filterTranslation.Pages]
          )
        })
      );
    } else {
      dispatch(updatePageUrlData({ ...modifyPageURLData(graphData) }));
    }

    if (isCompare) {
      graphData = pageURLGraphData2?.data;

      if (selectedFilters[filterTranslation.Pages]) {
        dispatch(
          updateComparePageUrlData({
            ...filterAccordingToSelected(
              pageUrlData2,
              selectedFilters[filterTranslation.Pages]
            )
          })
        );
      } else
        dispatch(updateComparePageUrlData({ ...modifyPageURLData(graphData) }));
    }
  };

  const updateSource = (sourceGraphData) => {
    if (selectedFilters[filterTranslation.Source]) {
      dispatch(
        updateSourceData({
          ...filterAccordingToSelected(
            sourceData1,
            selectedFilters[filterTranslation.Source]
          )
        })
      );
    } else {
      dispatch(
        updateSourceData({
          labels: sourceGraphData.map((ele) => ele.label),
          dataset: sourceGraphData.map((ele) => ele.visitors),
          visitors: sourceGraphData.map((ele) => ele.visitors)
        })
      );
    }
  };

  const updateMedium = (mediumGraphData) => {
    if (selectedFilters[filterTranslation.Medium]) {
      dispatch(
        updateMediumData({
          ...filterAccordingToSelected(
            mediumData1,
            selectedFilters[filterTranslation.Medium]
          )
        })
      );
    } else {
      dispatch(
        updateMediumData({
          labels: mediumGraphData.map((ele) => ele.label),
          dataset: mediumGraphData.map((ele) => ele.visitors),
          visitors: mediumGraphData.map((ele) => ele.visitors)
        })
      );
    }
  };

  const updateCampaign = (campaignGraphData) => {
    if (selectedFilters[filterTranslation.Campaign]) {
      dispatch(
        updateCampaignData({
          ...filterAccordingToSelected(
            campaignData1,
            selectedFilters[filterTranslation.Campaign]
          )
        })
      );
    } else {
      dispatch(
        updateCampaignData({
          labels: campaignGraphData.map((ele) => ele.label),
          dataset: campaignGraphData.map((ele) => ele.visitors),
          visitors: campaignGraphData.map((ele) => ele.visitors)
        })
      );
    }
  };

  const updateTerm = (termGraphData) => {
    if (selectedFilters[filterTranslation.Term]) {
      dispatch(
        updateTermData({
          ...filterAccordingToSelected(
            termData1,
            selectedFilters[filterTranslation.Term]
          )
        })
      );
    } else {
      dispatch(
        updateTermData({
          labels: termGraphData.map((ele) => ele.label),
          dataset: termGraphData.map((ele) => ele.visitors),
          visitors: termGraphData.map((ele) => ele.visitors)
        })
      );
    }
  };

  const updateContent = (contentGraphData) => {
    if (selectedFilters[filterTranslation.Content]) {
      dispatch(
        updateContentData({
          ...filterAccordingToSelected(
            contentData1,
            selectedFilters[filterTranslation.Content]
          )
        })
      );
    } else {
      dispatch(
        updateContentData({
          labels: contentGraphData.map((ele) => ele.label),
          dataset: contentGraphData.map((ele) => ele.visitors),
          visitors: contentGraphData.map((ele) => ele.visitors)
        })
      );
    }
  };

  // separating data based on device, browser, country, etc..
  const separateData1 = (data) => {
    dispatch(updateInsights(getInsights(data)));
    /*
    dataFilterFunction - returns an object 
     */

    const personaGraphData = getGraphDataSorted(
      dataFilterFunction(data, dataValues.persona),
      {
        visitors: true,
        views: true
      }
    );

    const pageGraphData = getViewsVisitorsData(
      dataFilterFunction(data, dataValues.date),
      isCompare
        ? {
            startDate: compareDateRange.startDate,
            endDate: compareDateRange.endDate
          }
        : dateRange,
      timeType
    );

    const deviceGraphData = getGraphDataSorted(
      dataFilterFunction(data, dataValues.device),
      { visitors: true }
    );
    const browserGraphData = getGraphDataSorted(
      dataFilterFunction(data, dataValues.browser),
      { visitors: true }
    );
    const countryGraphData = getGraphDataSorted(
      dataFilterFunction(data, dataValues.country),
      { visitors: true }
    );

    const sourceGraphData = getGraphDataSorted(
      dataFilterFunction(data, dataValues.source),
      { visitors: true }
    );

    const mediumGraphData = getGraphDataSorted(
      dataFilterFunction(data, dataValues.medium),
      { visitors: true }
    );

    const campaignGraphData = getGraphDataSorted(
      dataFilterFunction(data, dataValues.campaign),
      { visitors: true }
    );
    const termGraphData = getGraphDataSorted(
      dataFilterFunction(data, dataValues.term),
      { visitors: true }
    );
    const contentGraphData = getGraphDataSorted(
      dataFilterFunction(data, dataValues.content),
      { visitors: true }
    );

    if (selectedFilters[filterTranslation.Browsers]) {
      dispatch(
        updateBrowsersData({
          ...filterAccordingToSelected(
            browsersData1,
            selectedFilters[filterTranslation.Browsers]
          )
        })
      );
    } else {
      dispatch(
        updateBrowsersData({
          labels: browserGraphData.map((ele) => ele.label),
          dataset: browserGraphData.map((ele) => ele.visitors),
          visitors: browserGraphData.map((ele) => ele.visitors)
        })
      );
    }
    if (selectedFilters[filterTranslation.Devices]) {
      dispatch(
        updateDevicesData({
          ...filterAccordingToSelected(
            devicesData1,
            selectedFilters[filterTranslation.Devices]
          )
        })
      );
    } else {
      dispatch(
        updateDevicesData({
          labels: deviceGraphData.map((ele) => ele.label),
          dataset: deviceGraphData.map((ele) => ele.visitors),
          visitors: deviceGraphData.map((ele) => ele.visitors)
        })
      );
    }
    if (
      selectedFilters[filterTranslation.Country] ||
      selectedFilters[filterTranslation.World]
    ) {
      dispatch(
        updateCountriesData({
          ...filterAccordingToSelected(
            countriesData1,
            selectedFilters[filterTranslation.Country]
              ? selectedFilters[filterTranslation.Country]
              : selectedFilters[filterTranslation.World]
          )
        })
      );
    } else {
      dispatch(
        updateCountriesData({
          labels: countryGraphData.map((ele) => ele.label),
          dataset: countryGraphData.map((ele) => ele.visitors),
          visitors: countryGraphData.map((ele) => ele.visitors)
        })
      );
      dispatch(
        updateWorldData({
          labels: countryGraphData.map((ele) => ele.label),
          dataset: countryGraphData.map((ele) => ele.visitors)
        })
      );
    }
    if (!selectedFilters[filterTranslation.Persona]) {
      dispatch(
        updatePersonaData({
          labels: personaGraphData.map((ele) => ele.label),
          visitors: personaGraphData.map((ele) => ele.visitors),
          views: personaGraphData.map((ele) => ele.views)
        })
      );
    }

    updateSource(sourceGraphData);
    updateMedium(mediumGraphData);
    updateCampaign(campaignGraphData);
    updateTerm(termGraphData);
    updateContent(contentGraphData);

    // Line chart with views and visitors

    dispatch(
      updateViewsVisitorsData({
        labels: pageGraphData.map((ele) => ele.label),
        views: pageGraphData.map((ele) => ele.views),
        visitors: pageGraphData.map((ele) => ele.visitors)
      })
    );
  };
  const separateData2 = (data) => {
    dispatch(updateCompareInsights(getInsights(data)));

    /*
    dataFilterFunction - returns an object 
     */
    const personaGraphData = getGraphDataSorted(
      dataFilterFunction(data, dataValues.persona),
      {
        visitors: true,
        views: true
      }
    );

    const pageGraphData = getViewsVisitorsData(
      dataFilterFunction(data, dataValues.date),
      {
        startDate: compareDateRange.compareStartDate,
        endDate: compareDateRange.compareEndDate
      },
      timeType
    );

    const deviceGraphData = getGraphDataSorted(
      dataFilterFunction(data, dataValues.device),
      {
        visitors: true
      }
    );
    const browserGraphData = getGraphDataSorted(
      dataFilterFunction(data, dataValues.browser),
      {
        visitors: true
      }
    );
    const countryGraphData = getGraphDataSorted(
      dataFilterFunction(data, dataValues.country),
      {
        visitors: true
      }
    );

    const sourceGraphData = getGraphDataSorted(
      dataFilterFunction(data, dataValues.source),
      { visitors: true }
    );

    const mediumGraphData = getGraphDataSorted(
      dataFilterFunction(data, dataValues.medium),
      { visitors: true }
    );

    const campaignGraphData = getGraphDataSorted(
      dataFilterFunction(data, dataValues.campaign),
      { visitors: true }
    );
    const termGraphData = getGraphDataSorted(
      dataFilterFunction(data, dataValues.term),
      { visitors: true }
    );
    const contentGraphData = getGraphDataSorted(
      dataFilterFunction(data, dataValues.content),
      { visitors: true }
    );

    if (!selectedFilters[filterTranslation.Source]) {
      dispatch(
        updateCompareSourceData({
          labels: sourceGraphData.map((ele) => ele.label),
          dataset: sourceGraphData.map((ele) => ele.visitors),
          visitors: sourceGraphData.map((ele) => ele.visitors)
        })
      );
    }

    if (!selectedFilters[filterTranslation.Medium]) {
      dispatch(
        updateCompareMediumData({
          labels: mediumGraphData.map((ele) => ele.label),
          dataset: mediumGraphData.map((ele) => ele.visitors),
          visitors: mediumGraphData.map((ele) => ele.visitors)
        })
      );
    }

    if (!selectedFilters[filterTranslation.Campaign]) {
      dispatch(
        updateCompareCampaignData({
          labels: campaignGraphData.map((ele) => ele.label),
          dataset: campaignGraphData.map((ele) => ele.visitors),
          visitors: campaignGraphData.map((ele) => ele.visitors)
        })
      );
    }

    if (!selectedFilters[filterTranslation.Term]) {
      dispatch(
        updateCompareTermData({
          labels: termGraphData.map((ele) => ele.label),
          dataset: termGraphData.map((ele) => ele.visitors),
          visitors: termGraphData.map((ele) => ele.visitors)
        })
      );
    }

    if (!selectedFilters[filterTranslation.Content]) {
      dispatch(
        updateCompareContentData({
          labels: contentGraphData.map((ele) => ele.label),
          dataset: contentGraphData.map((ele) => ele.visitors),
          visitors: contentGraphData.map((ele) => ele.visitors)
        })
      );
    }

    if (!selectedFilters[filterTranslation.Browsers])
      dispatch(
        updateCompareBrowsersData({
          labels: browserGraphData.map((ele) => ele.label),
          dataset: browserGraphData.map((ele) => ele.visitors),
          visitors: browserGraphData.map((ele) => ele.visitors)
        })
      );

    if (!selectedFilters[filterTranslation.Devices])
      dispatch(
        updateCompareDevicesData({
          labels: deviceGraphData.map((ele) => ele.label),
          dataset: deviceGraphData.map((ele) => ele.visitors),
          visitors: deviceGraphData.map((ele) => ele.visitors)
        })
      );

    if (
      !selectedFilters[filterTranslation.Country] &&
      !selectedFilters[filterTranslation.World]
    ) {
      dispatch(
        updateCompareCountriesData({
          labels: countryGraphData.map((ele) => ele.label),
          dataset: countryGraphData.map((ele) => ele.visitors),
          visitors: countryGraphData.map((ele) => ele.visitors)
        })
      );
      dispatch(
        updateCompareWorldData({
          labels: countryGraphData.map((ele) => ele.label),
          dataset: countryGraphData.map((ele) => ele.visitors)
        })
      );
    }
    if (!selectedFilters[filterTranslation.Persona])
      dispatch(
        updateComparePersonaData({
          labels: personaGraphData.map((ele) => ele.label),
          visitors: personaGraphData.map((ele) => ele.visitors),
          views: personaGraphData.map((ele) => ele.views)
        })
      );

    // Line chart with views and visitors

    dispatch(
      updateCompareViewsVisitorsData({
        labels: pageGraphData.map((ele) => ele.label),
        views: pageGraphData.map((ele) => ele.views),
        visitors: pageGraphData.map((ele) => ele.visitors)
      })
    );
  };

  // general function for filtering the data based on the common selected filters
  const filterData = (data, { entry, exit }) => {
    let tempData = data;

    const paramsSelected = {};
    if (selectedFilters[filterTranslation.Browsers]) {
      paramsSelected.browser = selectedFilters[filterTranslation.Browsers];
      tempData = tempData?.filter((ele) =>
        selectedFilters[filterTranslation.Browsers].includes(ele.browser)
      );
    }
    if (selectedFilters[filterTranslation.Devices]) {
      paramsSelected.device = selectedFilters[filterTranslation.Devices];
      tempData = tempData?.filter((ele) =>
        selectedFilters[filterTranslation.Devices].includes(ele.device)
      );
    }
    if (selectedFilters[filterTranslation.Country]) {
      paramsSelected.country = selectedFilters[filterTranslation.Country];
      tempData = tempData?.filter((ele) =>
        selectedFilters[filterTranslation.Country].includes(ele.country)
      );
    }
    if (selectedFilters[filterTranslation.World]) {
      paramsSelected.country = selectedFilters[filterTranslation.World];
      tempData = tempData?.filter((ele) =>
        selectedFilters[filterTranslation.World].includes(ele.country)
      );
    }
    if (selectedFilters[filterTranslation.Persona]) {
      paramsSelected.persona = selectedFilters[filterTranslation.Persona];
      tempData = tempData?.filter((ele) =>
        selectedFilters[filterTranslation.Persona].includes(ele.persona_label)
      );
    }

    if (selectedFilters[filterTranslation.Source]) {
      paramsSelected.source = selectedFilters[filterTranslation.Source];
      tempData = tempData?.filter((ele) =>
        selectedFilters[filterTranslation.Source].includes(ele.source)
      );
    }

    if (selectedFilters[filterTranslation.Medium]) {
      paramsSelected.medium = selectedFilters[filterTranslation.Medium];
      tempData = tempData?.filter((ele) =>
        selectedFilters[filterTranslation.Medium].includes(ele.medium)
      );
    }

    if (selectedFilters[filterTranslation.Campaign]) {
      paramsSelected.campaign = selectedFilters[filterTranslation.Campaign];
      tempData = tempData?.filter((ele) =>
        selectedFilters[filterTranslation.Campaign].includes(ele.campaign)
      );
    }

    if (selectedFilters[filterTranslation.Term]) {
      paramsSelected.term = selectedFilters[filterTranslation.Term];
      tempData = tempData?.filter((ele) =>
        selectedFilters[filterTranslation.Term].includes(ele.term)
      );
    }

    if (selectedFilters[filterTranslation.Content]) {
      paramsSelected.content = selectedFilters[filterTranslation.Content];
      tempData = tempData?.filter((ele) =>
        selectedFilters[filterTranslation.Content].includes(ele.content)
      );
    }

    if (entry) {
      paramsSelected.entry = true;
      tempData = tempData?.filter((ele) => ele.entry === 1);
    } else if (exit) {
      paramsSelected.exit = true;
      tempData = tempData?.filter((ele) => ele.exit === 1);
    } else if (!entry && !exit) {
      tempData = [...(tempData || [])];
    }

    setBodyParams(
      Object.keys(paramsSelected).length === 0 ? null : paramsSelected
    );
    return tempData;
  };

  useEffect(() => {
    if (Object.keys(selectedFilters).length === 0) {
      setBodyParams(null);
      setBodyPagesParams(null);
    }
    if (selectedFilters[filterTranslation.Pages] !== bodyPagesParams?.pages) {
      setBodyPagesParams({ pages: selectedFilters[filterTranslation.Pages] });
    }
    dispatch(
      updateFilteredData(
        filterData(shouldUpdateData ? graphSepData1?.data : allData1, {
          entry: selectedFilters?.Filter?.includes(
            t("analyticsPage.entryPages")
          ),
          exit: selectedFilters?.Filter?.includes(t("analyticsPage.exitPages"))
        })
      )
    );

    if (isCompare) {
      dispatch(
        updateCompareFilteredData(
          filterData(shouldUpdateData ? graphSepData2?.data : allData2, {
            entry: selectedFilters?.Filter?.includes(
              t("analyticsPage.entryPages")
            ),
            exit: selectedFilters?.Filter?.includes(
              t("analyticsPage.exitPages")
            )
          })
        )
      );
    }
  }, [selectedFilters, graphSepData1?.data, graphSepData2?.data, t, language]);

  useEffect(() => {
    if (pageURLGraphData1?.data) {
      updatePages();
    }
  }, [
    pageURLGraphData1?.data,
    pageURLGraphData2,
    isCompare,
    selectedFilters?.[filterTranslation.Pages]
  ]);

  useEffect(() => {
    dispatch(updateShouldUpdateData(false));
    if (isCompare) {
      dispatch(updateCompareShouldUpdateData(false));
    }
  }, [dateRange, compareDateRange, websiteID, isCompare, language]);

  useEffect(() => {
    if (filteredData1.length > 0) {
      separateData1(filteredData1);
    } else if (
      (shouldUpdateData && graphSepData1?.data?.length > 0) ||
      (!shouldUpdateData && allData1?.length > 0)
    ) {
      separateData1(shouldUpdateData ? graphSepData1?.data : allData1);
      if (!shouldUpdateData && !isCompare) {
        dispatch(updateShouldUpdateData(true));
      }
    }
    if (!isCompare)
      storage.setItem("selected", JSON.stringify(selectedFilters));
    if (isCompare) {
      if (filteredData2.length > 0) {
        separateData2(filteredData2);
        storage.setItem("selected", JSON.stringify(selectedFilters));
      } else if (
        (shouldUpdateData && graphSepData2?.data?.length > 0) ||
        (!shouldUpdateData && allData2?.length > 0)
      ) {
        separateData2(shouldUpdateData ? graphSepData2?.data : allData2);
        if (!shouldUpdateData) {
          dispatch(updateShouldUpdateData(true));
          dispatch(updateCompareShouldUpdateData(true));
        }
      }
    }
  }, [
    allData1,
    graphSepData1?.data,
    filteredData1,
    allData2,
    graphSepData2?.data,
    filteredData2,
    t,
    language
  ]);

  // analytics pages
  useEffect(() => {
    if (!isCompare) {
      dispatch(
        getAnalyticsPages(
          {
            website_id: websiteID,
            customer_id: customerId,
            dateRange: [{ ...dateRange }],
            ...(bodyParams || {})
          },
          t
        )
      );
    } else {
      dispatch(
        getCompareAnalyticsPages(
          {
            website_id: websiteID,
            dateRange: [
              {
                startDate: compareDateRange.startDate,
                endDate: compareDateRange.endDate
              },
              {
                startDate: compareDateRange.compareStartDate,
                endDate: compareDateRange.compareEndDate
              }
            ],
            customer_id: customerId,
            ...(bodyParams || {})
          },
          t
        )
      );
    }
  }, [bodyParams, isCompare, dateRange, compareDateRange, websiteID, t]);

  useEffect(() => {
    if (!isCompare) {
      console.log("customerId", customerId);
      dispatch(
        getDataItems(
          {
            website_id: websiteID,
            customer_id: customerId,
            dateRange: [{ ...dateRange }],
            ...(bodyPagesParams || {})
          },
          t
        )
      );
    } else {
      dispatch(
        getCompareDataItems({
          website_id: websiteID,
          dateRange: [
            {
              startDate: compareDateRange.startDate,
              endDate: compareDateRange.endDate
            },
            {
              startDate: compareDateRange.compareStartDate,
              endDate: compareDateRange.compareEndDate
            }
          ],
          customer_id: customerId,
          ...(bodyPagesParams || {})
        })
      );
    }
  }, [
    bodyPagesParams,
    isCompare,
    dateRange,
    compareDateRange,
    websiteID,
    language,
    t
  ]);

  // saving the selected filters fetched from local storage
  useEffect(() => {
    if (isCompare) {
      if (allData1.length > 0 || allData2.length > 0) {
        dispatch(updateSelectedFilters(storedFilter ?? {}));
      }
    } else if (allData1.length > 0) {
      dispatch(updateSelectedFilters(storedFilter ?? {}));
    }
  }, [allData1, allData2]);

  const updateCompareData = () => {
    dispatch(
      setCompareViewsVisitorsData(
        compareMergeViewsVisitors(viewsVisitorsData1, viewsVisitorsData2)
      )
    );

    dispatch(
      setComparePersonaData(
        compareMergeViewsVisitors(personaData1, personaData2)
      )
    );

    dispatch(
      setCompareDevicesData(compareAndMergeData(devicesData1, devicesData2))
    );
    dispatch(
      setCompareBrowsersData(compareAndMergeData(browsersData1, browsersData2))
    );
    const mergedCountryData = compareAndMergeData(
      countriesData1,
      countriesData2
    );

    dispatch(setCompareCountriesData(mergedCountryData));
    dispatch(setCompareWorldData(mergedCountryData));
    dispatch(
      setComparePageUrlData(compareAndMergeData(pageUrlData1, pageUrlData2))
    );

    dispatch(
      setCompareSourceData(compareAndMergeData(sourceData1, sourceData2))
    );

    dispatch(
      setCompareMediumData(compareAndMergeData(mediumData1, mediumData2))
    );

    dispatch(
      setCompareCampaignData(compareAndMergeData(campaignData1, campaignData2))
    );

    dispatch(setCompareTermData(compareAndMergeData(termData1, termData2)));

    dispatch(
      setCompareContentData(compareAndMergeData(contentData1, contentData2))
    );
  };

  const notificationTexts = {
    9: t("notification.pageViewsExhausted")
  };

  const getNotificationText = (notificationId) => {
    return notificationTexts[notificationId] || "Unknown Notification";
  };

  const cancelBanner = async () => {
    try {
      const res = await axios.post("/vivaWallet/cancel-banner", {
        bannerCancel: 1,
        notificationId: bannerDelete
      });
      if (res.data.result.success === true) {
        setBannerOpen(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      isCompare &&
      countriesData2?.labels?.length > 0 &&
      devicesData2?.labels?.length > 0 &&
      browsersData2?.labels?.length > 0 &&
      pageUrlData2?.labels?.length > 0 &&
      viewsVisitorsData2?.labels?.length > 0 &&
      personaData2?.labels?.length > 0
    ) {
      updateCompareData();
    }
  }, [
    isCompare,
    countriesData2,
    devicesData2,
    browsersData2,
    pageUrlData2,
    viewsVisitorsData2,
    personaData2,
    sourceData2,
    mediumData2,
    campaignData2,
    termData2,
    contentData2
  ]);

  return (
    <>
      <AuthorizedHeader />
      <div className="dashboard-body">
        <div className="container">
          {bannerNotification &&
            bannerNotification.notificationText &&
            bannerOpen && (
              <div className="banner-notification">
                {getNotificationText(bannerNotification.notificationText)}
                <button
                  type="button"
                  className="banner-close"
                  onClick={cancelBanner}
                >
                  <img src={closeButton} alt="settings-close" />
                </button>
              </div>
            )}
          <div className="flex-between filter-header">
            <FilterPill filterValues={selectedFilters} dashboard={dashboard} />
            <DateCalendar />
          </div>

          {isLoading && <Loading />}

          {!isLoading &&
            (isCompare ? (
              <CompareDashboard
                insightsTitle={insightsTitle}
                dashboard={dashboard}
                isCompare={isCompare}
                selectedFilters={selectedFilters}
                updateSelectedFilters={updateSelectedFilters}
              />
            ) : (
              <GeneralDashboard
                insightsTitle={insightsTitle}
                dashboard={dashboard}
                isCompare={isCompare}
                selectedFilters={selectedFilters}
                updateSelectedFilters={updateSelectedFilters}
              />
            ))}
        </div>
      </div>
    </>
  );
}
