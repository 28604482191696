import React, { useCallback, useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import ReactDOMServer from "react-dom/server";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup
} from "react-simple-maps";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { scaleLinear } from "d3-scale";
import { useDispatch, useSelector } from "react-redux";
import { insightsDiff } from "../../../../utils/dataFilter";
import worldMapData from "../../../../data/worldMapData.json";
import { countriesData } from "../../../../data/countryData";
import themeColors from "../../../../assets/stylesheets/_var.scss";
import "../assets/stylesheets/worldMap.scss";

export default function WorldMap({
  data,
  labels,
  compareData,
  diffData,
  updatePosition,
  togglePopUp,
  selectedFilters,
  updateFilters
}) {
  const { isCompare, countryISO } = useSelector((state) => state.generalData);

  const { t } = useTranslation();

  const [position, setPosition] = useState({ coordinates: [0, 0], zoom: 0.6 });

  // Handle region selection from the dropdown
  const handleRegionChange = (countryCode) => {
    const coord = countryISO[countryCode];
    setPosition({
      coordinates: [coord.longitude, coord.latitude],
      zoom: 3
    });
  };

  useEffect(() => {
    if (updatePosition) handleRegionChange(updatePosition);
  }, [updatePosition]);

  function handleMoveEnd(temp) {
    setPosition(temp);
  }

  const [mapData, setMapData] = useState([]);
  const [option, setOption] = useState("compare");

  const colorScale = scaleLinear()
    .domain([Math.min(...mapData), Math.max(...mapData)])
    .range([themeColors.primaryColor4, themeColors.primaryColor]);
  const colorScaleOnNotSelected = scaleLinear()
    .domain([Math.min(...mapData), Math.max(...mapData)])
    .range(["rgba(232, 240, 197, .4)", "rgba(52, 78, 65, .4)"]);

  const dispatch = useDispatch();

  function handleZoomIn() {
    if (position.zoom >= 4) return;
    setPosition((pos) => ({ ...pos, zoom: pos.zoom * 2 }));
  }

  function handleZoomOut() {
    if (position.zoom <= 1) return;
    setPosition((pos) => ({ ...pos, zoom: pos.zoom / 2 }));
  }

  const handleGeographyClick = useCallback(
    (geo, event) => {
      const coord = countriesData.filter(
        (ele) => ele.numeric === Number(geo.id)
      )[0];
      setPosition({
        coordinates: [coord.longitude, coord.latitude],
        zoom: 3
      });

      if (
        selectedFilters[t("analyticsPage.worldTitle")]?.includes(
          geo.properties.name
        )
      ) {
        const {
          [t("analyticsPage.countryTitle")]: remove,
          [t("analyticsPage.worldTitle")]: World,
          ...updatedFilters
        } = selectedFilters;

        if (remove.length > 1) {
          const i = remove.indexOf(geo.properties.name);
          const temp = remove.slice();
          temp.splice(i, 1);

          dispatch(
            updateFilters({
              [t("analyticsPage.countryTitle")]: [...temp],
              [t("analyticsPage.worldTitle")]: [...temp],
              ...updatedFilters
            })
          );
        } else dispatch(updateFilters({ ...updatedFilters }));
      } else if (
        !selectedFilters[t("analyticsPage.worldTitle")] ||
        event.metaKey ||
        event.ctrlKey
      ) {
        dispatch(
          updateFilters({
            ...selectedFilters,
            [t("analyticsPage.countryTitle")]: [
              ...(selectedFilters[t("analyticsPage.countryTitle")] ?? ""),
              geo.properties.name
            ],
            [t("analyticsPage.worldTitle")]: [
              ...(selectedFilters[t("analyticsPage.worldTitle")] ?? ""),
              geo.properties.name
            ]
          })
        );
      } else
        dispatch(
          updateFilters({
            ...selectedFilters,
            [t("analyticsPage.countryTitle")]: [geo.properties.name],
            [t("analyticsPage.worldTitle")]: [geo.properties.name]
          })
        );
    },
    [dispatch, selectedFilters]
  );

  useEffect(() => {
    if (!isCompare && data.length > 0) setMapData(data);
    else if (isCompare && diffData.length > 0) setMapData(diffData);
  }, [data, diffData]);

  useEffect(() => {
    if (isCompare) {
      if (option === "compare") setMapData(diffData);
      else if (option === "data1") setMapData(data);
      else if (option === "data2") setMapData(compareData);
    }
  }, [option]);

  const tooltipContent = (geo, index) => {
    if (isCompare && option === "compare") {
      return (
        <div className="tooltip">
          <div className="flex-between">
            {geo.properties.name}
            {insightsDiff(data[index], compareData[index])}
          </div>
          <div className="flex-between tooltip-compare-data">
            <span>{t("analyticsPage.visitors")} (Range A)</span>{" "}
            {data[index] ?? 0}
          </div>
          <div className="flex-between tooltip-data">
            <span>{t("analyticsPage.visitors")} (Range B)</span>{" "}
            {compareData[index] ?? 0}
          </div>
        </div>
      );
    }

    return (
      <div className="tooltip">
        <div>{geo.properties.name}</div>
        <div className="flex-between tooltip-data">
          <span>{t("analyticsPage.visitors")}</span> {mapData[index] ?? 0}
        </div>
      </div>
    );
  };

  return (
    <div className="world-map-graph">
      <div className="flex-between map-options">
        <div className="controls">
          <button type="button" className="zoom-in" onClick={handleZoomIn}>
            +
          </button>
          <button type="button" className="zoom-out" onClick={handleZoomOut}>
            -
          </button>
        </div>
        {isCompare && (
          <div className="radio-options">
            <label htmlFor="data1">
              <input
                type="radio"
                id="data1"
                name="option"
                onClick={(e) => setOption(e.target.id)}
              />
              Range A
            </label>

            <label htmlFor="data2">
              <input
                type="radio"
                id="data2"
                name="option"
                onClick={(e) => setOption(e.target.id)}
              />
              Range B
            </label>

            <label htmlFor="compare">
              <input
                type="radio"
                id="compare"
                name="option"
                defaultChecked
                onClick={(e) => setOption(e.target.id)}
              />
              Compare
            </label>
          </div>
        )}
      </div>

      <ComposableMap
        height={togglePopUp ? "500" : "300"}
        projection="geoMercator"
      >
        <ZoomableGroup
          zoom={position.zoom}
          center={position.coordinates}
          onMoveEnd={(e) => handleMoveEnd(e)}
          filterZoomEvent={(e) => {
            return e.type === "wheel" ? 0 : 1;
          }}
        >
          <Geographies geography={worldMapData}>
            {({ geographies }) => {
              return geographies.map((geo) => {
                let indexCountry;
                labels?.map((ele, i) => {
                  if (ele === geo.properties.name) {
                    indexCountry = i;
                  }
                  return indexCountry;
                });

                const getColor = () => {
                  if (!([t("analyticsPage.worldTitle")] in selectedFilters))
                    return colorScale(mapData[indexCountry]);
                  if (
                    selectedFilters[t("analyticsPage.worldTitle")]?.includes(
                      labels[indexCountry]
                    )
                  )
                    return themeColors.primaryColor;
                  return colorScaleOnNotSelected(mapData[indexCountry]);
                };

                return (
                  <Geography
                    data-tooltip-id="tooltip-for-worldmap"
                    data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                      tooltipContent(geo, indexCountry)
                    )}
                    key={geo.rsmKey}
                    geography={geo}
                    id={geo.id}
                    fill={indexCountry >= 0 ? getColor() : "#fff"}
                    stroke="#999999"
                    strokeWidth={0.5}
                    style={{
                      default: {
                        outline: "none"
                      },
                      hover: {
                        cursor: "pointer",
                        outline: "none"
                      },
                      pressed: {
                        outline: "none"
                      }
                    }}
                    onClick={(e) => handleGeographyClick(geo, e)}
                  />
                );
              });
            }}
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
      <Tooltip id="tooltip-for-worldmap" place="bottom-start" />
    </div>
  );
}

WorldMap.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array]).isRequired,
  compareData: PropTypes.oneOfType([PropTypes.array]),
  diffData: PropTypes.oneOfType([PropTypes.array]),
  labels: PropTypes.oneOfType([PropTypes.array]).isRequired,
  updatePosition: PropTypes.string,
  togglePopUp: PropTypes.bool,
  updateFilters: PropTypes.func.isRequired,
  selectedFilters: PropTypes.oneOfType([Object]).isRequired
};
