import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import DeleteApi from "./components/DeleteApi";
import { deleteApi, deleteAsaApi } from "./api/deleteApi";
import { Toastr } from "../../../components/Toastr/Toastr";

export default function Index({
  setActiveManageComponent,
  selectedRowData,
  selectMultipleRowData,
  handleChnageRender
}) {
  const [loading, setLoading] = useState(false);
  const { websiteID } = useSelector((state) => state.generalData);
  const { t } = useTranslation();

  const handleDelete = async () => {
    try {
      setLoading(true);
      let dataToDelete;
      if (selectedRowData) {
        dataToDelete = [{ id: selectedRowData.id, website_id: websiteID }];
      } else if (selectMultipleRowData) {
        dataToDelete = selectMultipleRowData.map((id) => ({
          id,
          website_id: websiteID
        }));
      }
      if (!dataToDelete) {
        Toastr.error(t("ManageApi.deleteApi.deleteError"));
        return;
      }
      const [apiResponse, asaResponse] = await Promise.all([
        deleteApi(dataToDelete),
        deleteAsaApi({ website_id: websiteID })
      ]);

      if (apiResponse.data.result.success && asaResponse.data.status === "ok") {
        setLoading(false);
        Toastr.success(t("ManageApi.deleteApi.deleteSuccess"));
        setActiveManageComponent(0);
        handleChnageRender();
      }
    } catch (error) {
      Toastr.error(t("ManageApi.wrongMessage"));
      setLoading(false);
    }
  };
  return (
    <DeleteApi
      setActiveManageComponent={setActiveManageComponent}
      loading={loading}
      handleDelete={handleDelete}
    />
  );
}

Index.propTypes = {
  setActiveManageComponent: PropTypes.func,
  selectedRowData: PropTypes.shape({
    id: PropTypes.number,
    apiName: PropTypes.string,
    status: PropTypes.number
  }),
  selectMultipleRowData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      apiName: PropTypes.string,
      status: PropTypes.number
    })
  ),
  handleChnageRender: PropTypes.func
};
