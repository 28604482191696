/* eslint-disable */
import * as React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { DataGrid } from "@mui/x-data-grid";
import themeColors from "../../assets/stylesheets/_var.scss";
import "./dataTable.scss";
import SearchFilter from "../inputElements/selectInput/SearchFilter";
import SearchBar from "../inputElements/Input/SearchBar";

export default function DataTable({
  rows,
  columns,
  getRowId,
  onRowClick,
  onRowsSelect,
  CustomButton,
  tableType,
  pageSize,
  info,
  search = false,
  searchFilters,
  searchFilter,
  setSearchFilter,
  handleSearch
}) {
  const { t } = useTranslation();

  const onRowsSelectionHandler = (ids) => {
    if (tableType === 1) {
      const selectedRowData = rows.filter((eachRow) =>
        ids.includes(eachRow.website_event_relation_id)
      );
      onRowsSelect(selectedRowData);
    } else if (tableType === 2) {
      const selectedRowData = rows.filter((eachRow) =>
        ids.includes(eachRow.image_id)
      );
      onRowsSelect(selectedRowData);
    } else {
      onRowsSelect(ids);
    }
  };

  return (
    <div className="data-table">
      {/* Search filter and search bar */}
      {search && (
        <div className="flex-between">
          <div className="info">{info}</div>
          <div className="flex">
            {/* Search Filter */}
            <div className="search-position">
              <SearchFilter
                searchFilters={searchFilters}
                searchFilter={searchFilter}
                setSearchFilter={setSearchFilter}
              />
            </div>
            {/* Search Filter */}

            {/* Search bar */}
            <div>
              <SearchBar onSearch={handleSearch} />
            </div>
            {/* Search bar ends */}
          </div>
        </div>
      )}
      {/* Search filter and search bar ends */}
      <DataGrid
        rows={rows}
        columns={columns}
        components={{
          ColumnMenuIcon: CustomButton
        }}
        getRowId={getRowId}
        onRowClick={(params) => onRowClick(params.row)}
        onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize }
          }
        }}
        sx={{ "&, [class^=MuiDataGrid]": { border: "none" } }}
        pageSizeOptions={[pageSize, 10, 20, 50]}
        checkboxSelection
        style={{
          fontFamily: themeColors.fontQuickSand,
          color: themeColors.primaryText
        }}
        localeText={{
          columnMenuSortAsc: t("datagrid.sortAsc"),
          columnMenuSortDesc: t("datagrid.sortDesc"),
          columnMenuFilter: t("datagrid.filter"),
          columnMenuHideColumn: t("datagrid.hideColumn"),
          columnMenuManageColumns: t("datagrid.manageColumn"),
          MuiTablePagination: {
            style: {
              fontFamily: themeColors.fontQuickSand,
              fontSize: "16px",
              fontWeight: "600",
              lineHeight: "20px",
              color: themeColors.primaryText
            },
            labelRowsPerPage: t("datagrid.rowsPerPage")
          }
        }}
      />
    </div>
  );
}

DataTable.propTypes = {
  rows: PropTypes.oneOfType([PropTypes.array]).isRequired,
  columns: PropTypes.oneOfType([PropTypes.array]).isRequired,
  getRowId: PropTypes.func,
  onRowClick: PropTypes.func,
  tableType: PropTypes.number,
  onRowsSelect: PropTypes.func,
  CustomButton: PropTypes.func,
  pageSize: PropTypes.number
};

DataTable.defaultProps = {
  pageSize: 5
};
