/* eslint-disable */
import React from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import viewlist from "../../assets/icons/Union.png";
import grid from "../../assets/icons/grid.png";
import sort from "../../assets/icons/sort.svg";
import close from "../../assets/icons/close.png";
import backbutton from "../../assets/icons/backbutton.png";
import nextbutton from "../../assets/icons/nextbutton.png";
import themeColors from "../../assets/stylesheets/_var.scss";
import "../stylesheets/uploadImages.scss";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import AuthorizedHeader from "../../components/layouts/AuthorizedHeader";
import { ButtonDark } from "../../components/inputElements/buttons/MainButton";
import { useState } from "react";
import { Dialog, IconButton } from "@mui/material";
import UploadedImage from "../Components/UploadedImage";
import OpenedImageModel from "../Components/OpenedImageModel";
import ImageComponent from "../Components/ImageComponent";
import DataTable from "../../components/Table/DataTable";
import AddImageModel from "../Components/AddImageModel";
import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { deleteImages, getAllImages } from "../apis/uploadImagesAPI";
import axios from "../../api/axios";
import Toastr from "../../components/Toastr/Toastr";
import DeleteComponent from "../../components/DeletePopUp/DeleteComponent";
import SearchBar from "../../components/inputElements/Input/SearchBar";
import SearchFilter from "../../components/inputElements/selectInput/SearchFilter";
import { TailSpin } from "react-loader-spinner";

function CustomButton() {
  return (
    <IconButton>
      <img src={sort} alt="sort" />
    </IconButton>
  );
}

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right"
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0"
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        )
      }
    }
  }
}));

export default function UploadImages() {
  const [loading, setLoading] = useState(false);
  const [allImages, setAllImages] = useState([]);
  const [searchedPhotosList, setSearchedPhotosList] = useState(allImages);
  const [photos, setPhotos] = useState([]);
  const [filteredPhotos, setFilteredPhotos] = useState([]);
  const [searchFilter, setSearchFilter] = useState("name");
  const [sortCriteria, setSortCriteria] = useState("name");
  const [gridView, setIsGridView] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [imagePopup, setImagePopup] = useState(false);
  const [addImagePopUp, setAddImagePopUp] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const [uploadedImages, setUploadedImages] = useState([]);
  const [uploadedImageDetails, setUploadedImageDetails] = useState([]);

  const [selectedImagesNew, setSelectedImagesNew] = useState([]);

  const [loader, setLoader] = useState(true);

  const [anchorEl, setAnchorEl] = useState(null);

  const { websiteID } = useSelector((state) => state.generalData);

  const { t } = useTranslation();

  const columns = [
    {
      field: "file_name",
      headerName: t("tagManager.Name"),
      flex: 1
    },
    {
      field: "date",
      headerName: t("uploadImages.uploadDate"),
      flex: 1
    }
  ];

  const searchFilters = [
    {
      name: t("tagManager.Name"),
      value: "name"
    }
  ];

  const open = Boolean(anchorEl);

  const handleSortClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getImages = async () => {
    setLoader(true);
    try {
      const res = await getAllImages(websiteID);
      console.log("res====>", res);
      const allImages =
        res?.data?.imageUrl?.length > 0 ? res?.data?.imageUrl : [];
      setAllImages(allImages);
      setLoader(false);
    } catch (error) {
      setLoader(true);
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    getImages();
  }, []);

  const sortImagesByName = (images) => {
    return images
      ?.slice()
      ?.sort((a, b) => a?.file_name?.localeCompare(b?.file_name));
  };

  const sortImagesByNameDescending = (images) => {
    return images
      ?.slice()
      ?.sort((a, b) => b?.file_name?.localeCompare(a?.file_name));
  };

  const sortImagesByDate = (images) => {
    return images
      ?.slice()
      ?.sort((a, b) => new Date(a?.date) - new Date(b?.date));
  };

  const handleSortChange = (criteria) => {
    setSortCriteria(criteria);
    if (criteria === "name") {
      setAllImages(sortImagesByName(allImages));
    } else if (criteria === "date") {
      setAllImages(sortImagesByDate(allImages));
    } else if (criteria === "descending") {
      setAllImages(sortImagesByNameDescending(allImages));
    }
    setAnchorEl(null);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedImagesNew([]);
    } else {
      setSelectedImagesNew(
        allImages?.map((image) => ({
          image_id: image.image_id,
          file_name: image.file_name
        }))
      );
    }
    setSelectAll(!selectAll);
  };

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleAddImagePopUpOpen = () => {
    setAddImagePopUp(true);
  };

  const handleAddImagePopUpClose = () => {
    setUploadedImages([]);
    setUploadedImageDetails([]);
    setAddImagePopUp(false);
  };

  const handleRowsSelect = (selectedData) => {
    const imagesInfo = selectedData?.map((image) => ({
      image_id: image.image_id,
      file_name: image.file_name
    }));
    setSelectedImagesNew(imagesInfo);
  };

  const handleRowClick = (params) => {
    const index = allImages?.findIndex(
      (image) => image?.image_id === params?.image_id
    );
    setSelectedImagesNew((prevSelectedImages) => {
      const isAlreadySelected = prevSelectedImages.some(
        (image) => image.image_id === params.image_id
      );

      if (!isAlreadySelected) {
        return [...prevSelectedImages, params];
      }

      return prevSelectedImages;
    });
    setCurrentImageIndex(index);
    setImagePopup(true);
  };

  const handleImageClickNew = (index, photo) => {
    setCurrentImageIndex(index);
    setSelectedImagesNew((prevSelectedImages) => {
      const isAlreadySelected = prevSelectedImages.some(
        (image) => image.image_id === photo.image_id
      );

      if (!isAlreadySelected) {
        return [...prevSelectedImages, photo];
      }

      return prevSelectedImages;
    });
    setImagePopup(true);
  };

  const handlePreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : prevIndex
    );
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex < allImages?.length - 1 ? prevIndex + 1 : prevIndex
    );
  };

  const formatFileSize = (sizeInBytes) => {
    return sizeInBytes / (1024 * 1024);
  };

  const handleFileUpload = (files) => {
    const newImages = Array.from(files).map((file) => ({
      name: file?.name,
      date: new Date().toLocaleDateString(),
      url: URL.createObjectURL(file),
      file: file,
      formattedSize: formatFileSize(file.size)
    }));
    const newImageNames = Array.from(files).map((file) => file?.name);
    setUploadedImageDetails((prevImages) => [...prevImages, ...newImages]);
    setUploadedImages((prevImages) => [...prevImages, ...newImageNames]);
  };

  const handleDrop = (event) => {
    event?.preventDefault();
    handleFileUpload(event?.dataTransfer?.files);
  };

  const handleDragOver = (event) => {
    event?.preventDefault();
  };

  const handleClick = () => {
    document?.getElementById("fileInput")?.click();
  };

  const handleUploadImages = async () => {
    if (uploadedImageDetails?.length === 0) {
      Toastr.error(t("uploadImages.uploadValidation"));
    } else {
      setLoading(true);
      const formData = new FormData();
      const fileNames = uploadedImageDetails.map((image) => image.name);
      uploadedImageDetails?.forEach((image) => {
        formData?.append("file", image?.file);
      });
      formData.append("website_id", websiteID);
      formData.append("file", JSON.stringify(fileNames));
      try {
        const response = await axios.post("wiget/uploadImage", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
        setLoading(false);
        handleAddImagePopUpClose();
        Toastr.success("Images uploaded successfully");
        await getImages();
        console.log("Upload successful", response.data);
      } catch (error) {
        console.error("Error uploading images", error);
        setLoading(false);
      }
    }
  };

  const deletedIds = selectedImagesNew?.map((image) => image?.image_id);

  const handleDeleteImages = async () => {
    setLoading(true);
    try {
      const res = await deleteImages({
        website_id: websiteID,
        images: selectedImagesNew
      });
      console.log("res====>", res);
      if (res?.data?.success) {
        const updatedPhotos = photos?.filter(
          (photo) => !deletedIds.includes(photo.image_id)
        );
        const updatedListPhotos = allImages?.filter(
          (photo) => !deletedIds.includes(photo.image_id)
        );
        setPhotos(updatedPhotos);
        setAllImages(updatedListPhotos);
        Toastr.success("Deleted images successfully");
        setShowDeletePopup(false);
        setImagePopup(false);
      } else {
        Toastr.error("Failed to delete images. Please try again.");
      }
      await getImages();
    } catch (error) {
      console.log(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (searchTerm) => {
    if (searchTerm?.length >= 1) {
      const filtered = photos?.filter((photo) =>
        photo?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
      );
      const listFiltered = allImages?.filter((photo) =>
        photo?.file_name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
      );
      setFilteredPhotos(filtered);
      setSearchedPhotosList(listFiltered);
    } else {
      setFilteredPhotos(photos);
      setSearchedPhotosList(allImages);
    }
  };

  console.log("photos====>", photos, "allImages====>", allImages);

  return (
    <>
      <AuthorizedHeader />
      <div
        className="uploadImages"
        style={{
          backgroundColor: themeColors.background,
          borderRadius: "10px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          marginLeft: "10%",
          marginRight: "10%",
          marginTop: "4%"
        }}
      >
        <div>
          <div className="flex-between upload-bottom">
            <div className="modules-heading upload-left">
              {t("uploadImages.uploadImages")}
            </div>

            <div
              className="flex"
              style={{
                paddingRight: "7%"
              }}
            >
              <div type="button">
                {gridView ? (
                  <div
                    style={{
                      paddingLeft: "5%"
                    }}
                  >
                    <ImageComponent
                      src={viewlist}
                      onClick={() => {
                        setIsGridView(false);
                        getImages();
                      }}
                    />
                  </div>
                ) : (
                  <div>
                    <ImageComponent
                      src={grid}
                      onClick={() => {
                        setIsGridView(true);
                        getImages();
                      }}
                    />
                  </div>
                )}
              </div>

              {/* Sort options */}
              {gridView ? (
                <div>
                  <img
                    className="sort-icon"
                    onClick={handleSortClick}
                    src={sort}
                  />
                  <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                      "aria-labelledby": "demo-customized-button"
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={() => handleSortChange("name")}
                      disableRipple
                      style={{
                        fontFamily: themeColors.fontQuickSand,
                        fontWeight: "500"
                      }}
                    >
                      {t("uploadImages.sortByName")}
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleSortChange("date")}
                      disableRipple
                      style={{
                        fontFamily: themeColors.fontQuickSand,
                        fontWeight: "500"
                      }}
                    >
                      {t("uploadImages.sortByDate")}
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleSortChange("name")}
                      disableRipple
                      style={{
                        fontFamily: themeColors.fontQuickSand,
                        fontWeight: "500"
                      }}
                    >
                      <ArrowUpwardIcon />
                      {t("uploadImages.sortByAsc")}
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleSortChange("descending")}
                      disableRipple
                      style={{
                        fontFamily: themeColors.fontQuickSand,
                        fontWeight: "500"
                      }}
                    >
                      <ArrowDownwardIcon />
                      {t("uploadImages.sortByDesc")}
                    </MenuItem>
                  </StyledMenu>
                </div>
              ) : null}
              {/* Sort options end */}

              <button
                className={`delete-multiple ${
                  isMobile ? "backgroundSizeMobile" : "backgroundSize"
                }`}
                type="button"
                onClick={() => {
                  if (selectedImagesNew?.length == 0) {
                    Toastr.error(t("uploadImages.deleteValidation"));
                  } else {
                    setShowDeletePopup(true);
                  }
                }}
              />

              {showDeletePopup && (
                <DeleteComponent
                  onCancel={() => setShowDeletePopup(false)}
                  loading={loading}
                  onConfirmDelete={() => {
                    handleDeleteImages();
                  }}
                />
              )}
              <ButtonDark
                buttonText={t("uploadImages.addImages")}
                onClick={handleAddImagePopUpOpen}
              />
            </div>
          </div>
        </div>

        {/* Search filter and search bar for grid view */}
        {gridView && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "7%",
              paddingTop: "12px",
              paddingBottom: "20px"
            }}
          >
            <div className="flex">
              {/* Search Filter */}
              <div className="search-filter">
                <SearchFilter
                  searchFilters={searchFilters}
                  searchFilter={searchFilter}
                  setSearchFilter={setSearchFilter}
                />
              </div>
              {/* Search Filter */}

              {/* Search bar */}
              <div>
                <SearchBar onSearch={handleSearch} />
              </div>
              {/* Search bar ends */}
            </div>
          </div>
        )}
        {/* Search filter and search bar for grid view ends */}

        {gridView ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingLeft: "7%",
              paddingRight: "7%",
              paddingBottom: "2%"
            }}
          >
            <div>
              <p
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "20.7px",
                  paddingBottom: "2%"
                }}
              >
                {t("uploadImages.allImages")}
              </p>
            </div>

            {photos?.length > 0 && (
              <div
                style={{
                  cursor: "pointer"
                }}
                onClick={handleSelectAll}
              >
                <p
                  style={{
                    fontWeight: "700",
                    fontSize: "16px",
                    lineHeight: "20.7px",
                    paddingBottom: "2%",
                    textDecoration: "underline"
                  }}
                >
                  {selectAll
                    ? t("uploadImages.unSelectAll")
                    : t("uploadImages.selectAll")}
                </p>
              </div>
            )}
          </div>
        ) : (
          <></>
        )}

        {loader && gridView ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "15%"
            }}
          >
            <TailSpin
              height="50"
              width="50"
              color={themeColors.primaryColor}
              ariaLabel="tail-spin-loading"
              radius="2"
              wrapperStyle={{}}
              wrapperClass="spinner"
              visible
            />
          </div>
        ) : !loader && gridView ? (
          <div
            style={
              {
                // height: "60vh",
                // overflowY: "auto"
              }
            }
          >
            {/* Uploaded image grid view */}
            <UploadedImage
              allImages={allImages}
              setPhotos={setPhotos}
              photos={filteredPhotos?.length > 0 ? filteredPhotos : photos}
              filteredPhotos={filteredPhotos}
              setFilteredPhotos={setFilteredPhotos}
              selecetedImages={selectedImagesNew}
              setSelectedImagesNew={setSelectedImagesNew}
              handleMouseEnter={handleMouseEnter}
              handleMouseLeave={handleMouseLeave}
              handleImageClick={handleImageClickNew}
              hoveredIndex={hoveredIndex}
            />
            {/* Uploaded image grid view ends */}
          </div>
        ) : (
          <div
            style={{
              width: "110vw",
              paddingLeft: "5%",
              paddingRight: "5%"
            }}
          >
            {loader ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  right: "10%",
                  paddingTop: "1%"
                }}
              >
                <TailSpin
                  height="50"
                  width="50"
                  color={themeColors.primaryColor}
                  ariaLabel="tail-spin-loading"
                  radius="2"
                  wrapperStyle={{}}
                  wrapperClass="spinner"
                  visible
                />
              </div>
            ) : (
              <div className="datatable-width">
                <DataTable
                  rows={
                    searchedPhotosList?.length > 0
                      ? searchedPhotosList
                      : allImages
                  }
                  columns={columns}
                  getRowId={(row) => row.image_id}
                  pageSize={7}
                  CustomButton={CustomButton}
                  onRowsSelect={handleRowsSelect}
                  onRowClick={handleRowClick}
                  tableType={2}
                  info="All images"
                  search
                  searchFilters={searchFilters}
                  searchFilter={searchFilter}
                  setSearchFilter={setSearchFilter}
                  handleSearch={handleSearch}
                />
              </div>
            )}
          </div>
        )}

        <Dialog
          open={addImagePopUp}
          onClose={handleAddImagePopUpClose}
          maxWidth="md"
          fullWidth
          className="user-management"
        >
          <AddImageModel
            loading={loading}
            close={close}
            handleAddImagePopUpClose={handleAddImagePopUpClose}
            uploadedImages={uploadedImageDetails}
            setUploadedImages={setUploadedImageDetails}
            handleClick={handleClick}
            handleDrop={handleDrop}
            handleDragOver={handleDragOver}
            handleFileUpload={handleFileUpload}
            handleUploadImages={handleUploadImages}
          />
        </Dialog>

        <Dialog
          open={imagePopup}
          onClose={() => {
            setCurrentImageIndex(null);
            setImagePopup(false);
          }}
          maxWidth="md"
          fullWidth
          className="user-management"
        >
          <OpenedImageModel
            backbutton={backbutton}
            nextbutton={nextbutton}
            close={close}
            loading={loading}
            setCurrentImage={setCurrentImage}
            setImagePopup={setImagePopup}
            showDeletePopup={showDeletePopup}
            setShowDeletePopup={setShowDeletePopup}
            handleDeleteImages={handleDeleteImages}
            images={allImages}
            currentImageIndex={currentImageIndex}
            selectedImagesNew={selectedImagesNew}
            handlePreviousImage={handlePreviousImage}
            handleNextImage={handleNextImage}
          />
        </Dialog>
      </div>
    </>
  );
}
