import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Toastr } from "../../../components/Toastr/Toastr";
import ForgotPasswordForm from "./components/ForgotPasswordForm";
import forgotPassword from "./api/ForgotPassword";
import storage from "../../../utils/storage";

export default function Index() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const { t } = useTranslation();

  const emailValidation = () => {
    const emailRegex = /^\S+@\S+\.\S+$/;
    if (!email) {
      return t("common.emailValidation.emailRequired");
    }
    if (!emailRegex.test(email)) {
      return t("common.emailValidation.invalidEmail");
    }
    return "";
  };

  const emailChangeHandler = (e) => {
    setEmail(e.target.value);
    setEmailError("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const emailValidationError = emailValidation();

    if (emailValidationError) {
      setEmailError(emailValidationError);
      return;
    }

    try {
      setLoading(true);
      const response = await forgotPassword({ email });
      if (response.data.status === "ok") {
        setLoading(false);
        Toastr.success(t("forgotPassword.resetLinkSentSuccessfully"));
        storage.setItem("email", email);
      }
      return;
    } catch (error) {
      setLoading(false);
      Toastr.error(error.response.data.error);
    }
  };

  return (
    <ForgotPasswordForm
      email={email}
      emailChangeHandler={emailChangeHandler}
      loading={loading}
      handleSubmit={handleSubmit}
      emailError={emailError}
    />
  );
}
