import axios from "../../../../api/axios";

const addApi = (payload) => axios.post("/manageApi/addManageApi", payload);

const getServiceName = (webisteId) =>
  axios.get(`/manageApi/getServiceName/${webisteId}`);

const addAsaAccount = (payload) =>
  axios.post("/asaManageApi/register", payload);

const getToken = (payload) => axios.post("/isi/token", payload);

export { addApi, getServiceName, addAsaAccount, getToken };
