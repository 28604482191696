import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import RegenarateIllustration from "../assets/regenarate.svg";
import {
  ButtonLight,
  ButtonDark
} from "../../../../components/inputElements/buttons/MainButton";
import "../stylesheets/regenarate.scss";
import generatePassword from "../../../../utils/generatePassword";

export default function RegenaratePassword({
  setRegenaratePassword,
  handleRegeneratePasswordClick
}) {
  const { t } = useTranslation();
  const handleContinue = () => {
    setRegenaratePassword(generatePassword(24));
    handleRegeneratePasswordClick();
  };
  return (
    <section className="regenarate-password-section">
      <div className="flex-column">
        <div className="regenarate-illustration">
          <img src={RegenarateIllustration} alt="regenarate-illustartion" />
        </div>
        <div className="details">
          <p>{t("ManageApi.editApi.regenararteTitle")}</p>
          <p className="api-name">
            {t("ManageApi.editApi.regenararteDetails")}
          </p>
        </div>
        <div className="button-combination flex">
          <ButtonLight
            buttonText={t("common.cancelButton")}
            onClick={handleRegeneratePasswordClick}
          />
          <ButtonDark
            buttonText={t("ManageApi.editApi.continue")}
            onClick={handleContinue}
          />
        </div>
      </div>
    </section>
  );
}

RegenaratePassword.propTypes = {
  setRegenaratePassword: PropTypes.func,
  handleRegeneratePasswordClick: PropTypes.func
};
