import React from "react";
import { useTranslation } from "react-i18next";
import ApiImagePlaceholder from "./assets/api-placeholder.svg";
import "./stylesheets/api-placeholder.scss";

export default function Index() {
  const { t } = useTranslation();
  return (
    <section className="api-placeholder-section">
      <div className="api-placeholder">
        <img src={ApiImagePlaceholder} alt="api-illustration" />
        <h3>{t("ManageApi.noApiPlaceholder.heading")}</h3>
        <p>{t("ManageApi.noApiPlaceholder.details")}</p>
      </div>
    </section>
  );
}
