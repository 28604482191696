/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText
} from "@mui/material";
import themeColors from "../../../assets/stylesheets/_var.scss";
import "../Input/input.scss";

export default function DropdownComponent({
  options,
  id,
  name,
  register,
  disabled,
  value,
  onChange,
  error,
  defaultValue,
  isRequired,
  minWidth = "100%",
  marginTop
}) {
  const { t } = useTranslation();

  const handleChange = (event) => {
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <FormControl
      variant="outlined"
      style={{
        minWidth: minWidth
        // height: "47px"
      }}
      error={!!error && isRequired}
      disabled={disabled}
    >
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={value}
        onChange={handleChange}
        placeholder="hi"
        {...(register &&
          register(name, { onChange: handleChange, required: isRequired }))}
        style={{
          fontFamily: themeColors.fontQuickSand,
          fontStyle: "normal",
          fontWeight: "500",
          fontSize: "0.875rem",
          borderColor: error ? themeColors.negative : themeColors.tertiaryText,
          marginTop: marginTop
        }}
        sx={{
          height: "2.9rem",
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: error
                ? themeColors.negative
                : themeColors.tertiaryText
            },
            "& fieldset": {
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: error
                ? themeColors.negative
                : themeColors.tertiaryText
            }
          },
          "& .MuiSelect-select": {
            padding: "8px",
            height: "auto"
          }
        }}
        defaultValue={defaultValue}
      >
        {defaultValue && (
          <MenuItem
            style={{
              fontFamily: themeColors.fontQuickSand,
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "0.875rem"
            }}
            key={defaultValue}
            value={defaultValue}
            disabled
          >
            {`--${defaultValue}--`}
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem
            style={{
              fontFamily: themeColors.fontQuickSand,
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "0.875rem"
            }}
            key={option.value}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {error && isRequired && (
        <FormHelperText
          style={{
            color: themeColors.negative,
            textAlign: "left",
            margin: "7px 0",
            fontSize: "1rem",
            fontFamily: themeColors.fontQuickSand,
            fontWeight: 500,
            letterSpacing: "0.8px"
          }}
        >
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
}

DropdownComponent.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func.isRequired, // Ensure onChange is required
  id: PropTypes.string,
  register: PropTypes.func, // Register can be a function
  name: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string
};
