/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import DeleteIllustration from "../assets/delete.svg";
import {
  ButtonLight,
  ButtonDark
} from "../../../../components/inputElements/buttons/MainButton";
import "../stylesheets/deleteApi.scss";

export default function DeleteApi({
  setActiveManageComponent,
  loading,
  handleDelete
}) {
  const { t } = useTranslation();
  return (
    <section className="delete-api-section">
      <div className="flex-column">
        <div className="delete-illustration">
          <img src={DeleteIllustration} alt="delete-illustartion" />
        </div>
        <div className="details">
          <p>{t("ManageApi.deleteApi.title")}</p>
        </div>
        <div className="button-combination flex">
          <ButtonLight
            buttonText={t("ManageApi.deleteApi.no")}
            onClick={() => setActiveManageComponent(0)}
          />
          <ButtonDark
            buttonText={
              loading ? t("common.loading") : t("ManageApi.deleteApi.yes")
            }
            onClick={handleDelete}
          />
        </div>
      </div>
    </section>
  );
}

DeleteApi.propTypes = {
  setActiveManageComponent: PropTypes.func,
  loading: PropTypes.bool,
  handleDelete: PropTypes.func
};
