import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import DataTable from "../../../../components/Table/DataTable";
import "../stylesheets/apiTable.scss";

export default function SavedApiTable({
  columns,
  rows,
  CustomButton,
  handleRowClick,
  handleRowSelect
}) {
  const { t } = useTranslation();
  return (
    <div className="saved-api-table">
      <h4>{t("ManageApi.savedApis.title")}</h4>
      <div className="api-table">
        <DataTable
          columns={columns}
          rows={rows}
          getRowId={(row) => row.id}
          CustomButton={CustomButton}
          onRowClick={handleRowClick}
          onRowsSelect={handleRowSelect}
        />
      </div>
    </div>
  );
}

SavedApiTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      headerName: PropTypes.string,
      flex: PropTypes.number,
      renderCell: PropTypes.func
    })
  ),
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      apiName: PropTypes.string,
      status: PropTypes.number
    })
  ),
  CustomButton: PropTypes.func,
  handleRowClick: PropTypes.func,
  handleRowSelect: PropTypes.func
};
