/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import {
  addBillingAddress,
  updateBillingAddress,
  vatChecker
} from "../api/subscriptionAPI";
import Input from "../../../../components/inputElements/Input/Input";
import {
  ButtonDark,
  ButtonLight
} from "../../../../components/inputElements/buttons/MainButton";

function LabelInputSet({ children }) {
  return <div className="flex-column">{children}</div>;
}

export default function AddEditBillingInfo({
  value,
  email,
  handleChangeModal,
  countryISO
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    watch,
    setError
  } = useForm({
    defaultValues: value ?? {
      firstName: "",
      lastName: "",
      companyName: "",
      address: "",
      email,
      city: "",
      country: "",
      pec: "",
      cap: "",
      vatNumber: ""
    }
  });

  const { t } = useTranslation();
  const { createdBy: customerId } = useSelector((state) => state.generalData);

  const addAddress = async (data, event) => {
    event.preventDefault();
    try {
      await addBillingAddress({ ...data, customer_id: customerId });
      handleChangeModal("changeAddress", { address: null });
    } catch (error) {
      // console.log(error);
    }
  };

  const updateAddress = async (data, event) => {
    event.preventDefault();
    try {
      await updateBillingAddress({ ...data, customer_id: customerId });
      handleChangeModal("changeAddress", { address: null });
    } catch (error) {
      // console.log(error);
    }
  };

  const getCountryCodeByName = (countryName) => {
    const countryCodes = Object.keys(countryISO);
    for (const code of countryCodes) {
      if (countryISO[code].name.toLowerCase() === countryName.toLowerCase()) {
        return code;
      }
    }
    return null;
  };

  const submitForm = async (data, event) => {
    event.preventDefault();

    // Check VAT number before submitting the form
    const vatNumber = getValues("vatNumber");
    if (vatNumber !== null && vatNumber !== "") {
      const countryCode = getValues("country");
      try {
        const res = await vatChecker({
          country_code: getCountryCodeByName(countryCode),
          vat_number: getValues("vatNumber")
        });

        if (res.data.status === false) {
          setError("vatNumber", {
            message: t("subscription.billing.vatNumberIncorrect")
          });
        } else {
          // VAT number is correct, proceed with form submission
          if (value) {
            await updateAddress(data, event);
          } else {
            await addAddress(data, event);
          }
          handleChangeModal("changeAddress", { address: null });
        }
      } catch (error) {
        // console.log(error);
      }
    } else {
      if (value) {
        await updateAddress(data, event);
      } else {
        await addAddress(data, event);
      }

      handleChangeModal("changeAddress", { address: null });
    }
  };

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <div className="grid">
        <div className="half-column-grid">
          <LabelInputSet>
            <label htmlFor="first-name" className="label-tag">
              {t("subscription.billing.firstName")}
              <span className="asterisk">*</span>
            </label>
            <Input
              id="first-name"
              type="text"
              name="firstName"
              register={register("firstName", { required: true })}
              error={
                errors.firstName && t("subscription.billing.firstNameRequired")
              }
            />
          </LabelInputSet>
          <LabelInputSet>
            <label htmlFor="last-name" className="label-tag">
              {t("subscription.billing.lastName")}
              <span className="asterisk">*</span>
            </label>
            <Input
              id="last-name"
              type="text"
              name="lastName"
              register={register("lastName", { required: true })}
              error={
                errors.lastName && t("subscription.billing.lastNameRequired")
              }
            />
          </LabelInputSet>
        </div>
        <LabelInputSet>
          <label htmlFor="company-name" className="label-tag">
            {t("subscription.billing.companyName")}
          </label>
          <Input
            id="company-name"
            type="text"
            name="companyName"
            register={register("companyName", {
              validate: (val) => {
                if (watch("vatNumber") && !val)
                  return t("subscription.billing.companyNameRequired");
                return null;
              }
            })}
            error={errors?.companyName?.message}
          />
        </LabelInputSet>
        <LabelInputSet>
          <label htmlFor="address" className="label-tag">
            {t("subscription.billing.invoicingAddress")}
            <span className="asterisk">*</span>
          </label>
          <Input
            id="address"
            type="text"
            name="address"
            register={register("address", { required: true })}
            error={errors.address && t("subscription.billing.addressRequired")}
          />
        </LabelInputSet>

        <LabelInputSet>
          <label htmlFor="email" className="label-tag">
            {t("subscription.billing.invoicingEmailAddress")}
            <span className="asterisk">*</span>
          </label>
          <Input
            id="email"
            type="email"
            name="email"
            register={register("email", { required: true })}
            error={errors.email && t("subscription.billing.emailIdRequired")}
          />
          <p className="helper-text">
            {t("subscription.billing.defaultMailsSentTo")}
          </p>
        </LabelInputSet>

        <div className="half-column-grid">
          <LabelInputSet>
            <label htmlFor="city" className="label-tag">
              {t("subscription.billing.city")}
              <span className="asterisk">*</span>
            </label>
            <Input
              id="city"
              type="text"
              name="city"
              register={register("city", { required: true })}
              error={errors.city && t("subscription.billing.cityRequired")}
            />
          </LabelInputSet>
          <LabelInputSet>
            <label htmlFor="country" className="label-tag">
              {t("subscription.billing.country")}
              <span className="asterisk">*</span>
            </label>
            <Input
              id="country"
              type="text"
              name="country"
              register={register("country", { required: true })}
              error={
                errors.country && t("subscription.billing.countryRequired")
              }
            />
          </LabelInputSet>
        </div>
        <LabelInputSet>
          <label htmlFor="cap" className="label-tag">
            {t("subscription.billing.cap")}
            <span className="asterisk">*</span>
          </label>
          <Input
            id="cap"
            type="number"
            name="cap"
            register={register("cap", { required: true })}
            error={errors.cap && t("subscription.billing.capRequired")}
          />
        </LabelInputSet>

        <div className="half-column-grid">
          <LabelInputSet>
            <label htmlFor="pec" className="label-tag">
              {t("subscription.billing.pecCodiseDestinatario")}
            </label>
            <Input
              id="pec"
              type="text"
              name="pec"
              register={register("pec", {
                validate: (val) => {
                  if (watch("country").toLowerCase() === "italy" && !val)
                    return t("subscription.billing.pecMandatoryForItaly");
                  return null;
                }
              })}
              error={errors?.pec?.message}
            />
          </LabelInputSet>
          <LabelInputSet>
            <label htmlFor="vat-number" className="label-tag">
              {t("subscription.billing.vatNumber")}
            </label>
            <Input
              id="vat-number"
              type="text"
              name="vatNumber"
              register={register("vatNumber")}
              error={errors?.vatNumber?.message}
            />
          </LabelInputSet>
        </div>
      </div>
      <div style={{ marginTop: "1em" }} className="flex-end">
        {value && (
          <ButtonLight
            buttonText={t("common.cancelButton")}
            onClick={() =>
              handleChangeModal("changeAddress", { address: null })
            }
          />
        )}
        <ButtonDark
          isSubmit
          buttonText={t("common.saveButton")}
          className="tagManagerButton"
        />
      </div>
    </form>
  );
}

AddEditBillingInfo.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object]),
  email: PropTypes.string,
  handleChangeModal: PropTypes.func,
  countryISO: PropTypes.oneOfType([PropTypes.object])
};

LabelInputSet.propTypes = {
  children: PropTypes.node
};
