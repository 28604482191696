/* eslint-disable */
import React from "react";
import { useTranslation } from "react-i18next";
import DeleteComponent from "../../components/DeletePopUp/DeleteComponent";
import { ButtonLight } from "../../components/inputElements/buttons/MainButton";
import themeColors from "../../assets/stylesheets/_var.scss";

export default function OpenedImageModel({
  backbutton,
  nextbutton,
  close,
  loading,
  setCurrentImage,
  setImagePopup,
  showDeletePopup,
  setShowDeletePopup,
  handleDeleteImages,
  images,
  currentImageIndex,
  handlePreviousImage,
  handleNextImage
}) {
  const { t } = useTranslation();

  return (
    <div>
      {/* Navigation buttons */}
      <div>
        {currentImageIndex > 0 && (
          <div
            className="left-button"
            onClick={handlePreviousImage}
            style={{
              cursor: "pointer",
              position: "fixed",
              top: "50%",
              left: "18%",
              transform: "translateY(-50%)",
              zIndex: 999
            }}
          >
            <img
              src={backbutton}
              alt="bin-icon"
              style={{
                border: "none",
                cursor: "pointer",
                width: "60px",
                height: "60px",
                position: "relative",
                right: "10px",
                top: "14px"
              }}
            />
          </div>
        )}

        {currentImageIndex < images.length - 1 && (
          <div
            className="right-button"
            onClick={handleNextImage}
            style={{
              position: "fixed",
              top: "50%",
              right: "17%",
              transform: "translateY(-50%)",
              zIndex: 999
            }}
          >
            <img
              src={nextbutton}
              alt="next-icon"
              style={{
                border: "none",
                cursor: "pointer",
                width: "60px",
                height: "60px",
                position: "relative",
                right: "10px",
                top: "14px"
              }}
            />
          </div>
        )}
      </div>
      {/* Navigation buttons ends */}

      <div>
        <div
          style={{
            position: "absolute",
            right: "0px",
            paddingRight: "20px",
            paddingTop: "20px"
          }}
        >
          <img
            src={close}
            onClick={() => {
              setCurrentImage(null);
              setImagePopup(false);
            }}
            style={{
              border: "none",
              cursor: "pointer",
              width: "20px",
              position: "relative",
              right: "0px"
            }}
          />
        </div>
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "20px"
          }}
          className="flex center"
        >
          <img
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              width: "500px",
              height: "500px",
              objectFit: "contain"
            }}
            src={images[currentImageIndex]?.image}
            alt={images[currentImageIndex]?.file_name}
          />
        </div>
        <div
          style={{
            paddingLeft: "5%",
            paddingTop: "2%"
          }}
          className="image-name"
        >
          <text>{images[currentImageIndex]?.file_name}</text>
        </div>
        <div
          style={{
            paddingLeft: "5%",
            paddingTop: "2%",
            color: themeColors.secondaryText
          }}
          className="image-date"
        >
          <text>
            {t("common.date")}: {images[currentImageIndex]?.date}
          </text>
        </div>

        <ButtonLight
          buttonText={t("common.confirmation.delete")}
          style={{
            marginLeft: "5%",
            marginTop: "2%"
          }}
          onClick={() => {
            setShowDeletePopup(true);
          }}
        />
        {showDeletePopup && (
          <DeleteComponent
            onCancel={() => setShowDeletePopup(false)}
            loading={loading}
            onConfirmDelete={() => {
              handleDeleteImages();
            }}
          />
        )}
      </div>
    </div>
  );
}
