import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { TailSpin } from "react-loader-spinner";
import PropTypes from "prop-types";
import downArrow from "../../assets/icons/downArrow.svg";
import themeColors from "../../assets/stylesheets/_var.scss";
import "./custom-accordian.scss";

export default function CustomAccordian({
  expanded,
  title,
  children,
  clickExpand,
  setClickExpand,
  loading,
  className,
  style
}) {
  return (
    <Accordion
      defaultExpanded={expanded}
      expanded={clickExpand}
      onChange={setClickExpand}
      className={className}
    >
      <AccordionSummary
        className="accordian-style"
        expandIcon={
          <div className="flex">
            <img src={downArrow} alt="down-arrow" />
            {loading && (
              <TailSpin
                height="30"
                width="30"
                color={themeColors.primaryColor}
                ariaLabel="tail-spin-loading"
                radius=".25"
                wrapperStyle={{}}
                wrapperClass=""
                visible
              />
            )}
          </div>
        }
      >
        <text style={style}>{title}</text>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}

CustomAccordian.propTypes = {
  expanded: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node,
  loading: PropTypes.bool,
  clickExpand: PropTypes.bool,
  setClickExpand: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.string
};
