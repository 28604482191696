/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import ServiceName from "./ServiceName";
import Input from "../../../../components/inputElements/Input/Input";
import {
  ButtonLight,
  ButtonDark
} from "../../../../components/inputElements/buttons/MainButton";
import CloseIcon from "../../../../assets/icons/closeModal.svg";
import CopyInput from "../../../../components/inputElements/Input/CopyInput";
import asaServerUrl from "../../../../data/AsaServerUrl";
import "../stylesheets/addApi.scss";

export default function AddApi({
  showCredentials,
  handleGenerateCredentials,
  setActiveManageComponent,
  serviceName,
  loading,
  handleChange,
  selectedService,
  handleAddApi,
  addApiLoading,
  userName,
  password,
  token,
  setRoomNumber
}) {
  const { t } = useTranslation();
  return (
    <section className="add-api-section">
      <div className="close-icon" onClick={() => setActiveManageComponent(0)}>
        <img src={CloseIcon} alt="close-icon" />
      </div>
      <h3
        style={{
          marginBottom: "10px"
        }}
        className="modules-heading"
      >
        {t("ManageApi.addApi")}
      </h3>
      <form onSubmit={handleAddApi}>
        <ServiceName
          serviceName={serviceName}
          loading={loading}
          handleChange={handleChange}
          selectedService={selectedService}
        />
        {selectedService.api_service_name === "ASA" && (
          <div className="gap-20">
            <Input
              type="number"
              label={t("ManageApi.createAPi.roomNumberLabel")}
              onChange={(e) => setRoomNumber(e.target.value)}
              placeholder="0"
            />
          </div>
        )}
        {!showCredentials && (
          <ButtonDark
            buttonText={t("ManageApi.createAPi.generateCred")}
            onClick={handleGenerateCredentials}
            disabled={serviceName.length === 0}
          />
        )}
        {showCredentials && (
          <div className="generated-credentials">
            <div className="heading-details">
              <h3 className="modules-heading heading-left">
                {t("ManageApi.createAPi.heading")}
              </h3>
            </div>
            {selectedService.api_service_name === "ASA" ? (
              <div className="credentails">
                <div className="gap-20">
                  <CopyInput
                    type="text"
                    label={t("ManageApi.createAPi.serverUrl")}
                    value={asaServerUrl}
                    readOnlyCondition
                  />
                </div>
                <div className="gap-20">
                  <CopyInput
                    type="text"
                    label={t("ManageApi.createAPi.user")}
                    value={userName}
                    readOnlyCondition
                  />
                </div>
                <div>
                  <CopyInput
                    type="text"
                    label={t("ManageApi.createAPi.password")}
                    value={password}
                    readOnlyCondition
                  />
                </div>
              </div>
            ) : (
              <div className="credential ISI">
                <div className="gap-20">
                  <CopyInput
                    type="text"
                    label={t("ManageApi.token")}
                    value={token}
                    readOnlyCondition
                  />
                </div>{" "}
              </div>
            )}
            <div
              className={
                selectedService.api_service_name === "ASA"
                  ? "button-combination flex"
                  : "button-combination flex extra-margin"
              }
            >
              <ButtonLight
                buttonText={t("common.cancelButton")}
                onClick={() => setActiveManageComponent(0)}
              />
              <ButtonDark
                isSubmit
                buttonText={
                  addApiLoading
                    ? t("ManageApi.createAPi.connecting")
                    : t("ManageApi.createAPi.connect")
                }
              />
            </div>
          </div>
        )}
      </form>
    </section>
  );
}

AddApi.propTypes = {
  showCredentials: PropTypes.bool,
  handleGenerateCredentials: PropTypes.func,
  setActiveManageComponent: PropTypes.func,
  serviceName: PropTypes.arrayOf(
    PropTypes.shape({
      company_type_id: PropTypes.number,
      api_service_id: PropTypes.number,
      api_service_name: PropTypes.string
    })
  ),
  loading: PropTypes.bool,
  handleChange: PropTypes.func,
  selectedService: PropTypes.shape({
    company_type_id: PropTypes.number,
    api_service_id: PropTypes.number,
    api_service_name: PropTypes.string
  }),
  handleAddApi: PropTypes.func,
  addApiLoading: PropTypes.bool,
  userName: PropTypes.string,
  password: PropTypes.string,
  token: PropTypes.string,
  setRoomNumber: PropTypes.func
};
