/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
import close from "../../../assets/icons/close.png";
import search from "../../../assets/icons/search.png";
import themeColors from "../../../assets/stylesheets/_var.scss";
import "./searchbar.scss";

export default function SearchBar({
  onSearch,
  height = "2.5rem",
  placeholder
}) {
  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (event) => {
    const value = event?.target?.value;
    setSearchTerm(value);
    onSearch(value);
  };

  const handleClear = () => {
    setSearchTerm("");
    onSearch("");
  };

  return (
    <div
      style={{
        position: "relative",
        display: "inline-block"
      }}
    >
      <input
        type="text"
        className="input-placeholder"
        value={searchTerm}
        onChange={handleChange}
        placeholder={placeholder}
        style={{
          position: "relative",
          marginTop: "1%",
          marginRight: "20%",
          paddingLeft: "50px",
          padding: "8px",
          width: "13rem",
          height: height,
          borderRadius: "9px",
          fontFamily: themeColors.fontQuickSand,
          fontStyle: "normal",
          fontWeight: "500",
          fontSize: "0.875rem",
          border: "1.5px solid #ccc"
        }}
      />
      <div className="flex">
        {/* Search Icon */}

        {!searchTerm && (
          <div
            style={{
              // position: "absolute",
              paddingLeft: "7px",
              // bottom: "34px",
              // top: "50%",
              // transform: "translateY(-70%)",
              background: "none",
              border: "none",
              cursor: "pointer"
            }}
          >
            <img
              src={search}
              style={{
                width: "24px",
                height: "24px",
                position: "absolute",
                top: "27%",
                right: "4px"
                // marginLeft: "2%"
              }}
              alt="Search"
            />
          </div>
        )}

        {/* Search Icon ends */}

        {/* Clear Icon */}

        {searchTerm && (
          <button
            type="button"
            onClick={handleClear}
            style={{
              // position: "absolute",
              // right: "10px",
              // bottom: "12px",
              // top: clearIconPos,
              // transform: "translateY(-5%)",
              background: "none",
              border: "none",
              cursor: "pointer"
            }}
          >
            <img
              src={close}
              style={{
                width: "15px",
                height: "15px",
                position: "absolute",
                right: "10px",
                top: "34%"
              }}
              alt="Clear"
            />
          </button>
        )}

        {/* Clear Icon ends */}
      </div>
    </div>
  );
}

SearchBar.propTypes = {
  onSearch: PropTypes.func.isRequired
};
