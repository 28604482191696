/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable */
import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import AuthorizedHeader from "../../components/layouts/AuthorizedHeader";
import { ButtonDark } from "../../components/inputElements/buttons/Buttons";
import { Toastr } from "../../components/Toastr/Toastr";
import NoApiPlaceholder from "../../features/ManageApi/NoApiPlaceholder";
import SavedApis from "../../features/ManageApi/SavedApis";
import AddIcon from "../../assets/icons/add.svg";
import DeleteIcon from "../../assets/icons/buttons.svg";
import storage from "../../utils/storage";
import AddApi from "../../features/ManageApi/AddApi";
import EditApi from "../../features/ManageApi/EditApi";
import DeleteApi from "../../features/ManageApi/DeleteApi";
import getManageApi from "../../features/ManageApi/SavedApis/api/savedApis";
import Loader from "../../components/Loader/Loader";
import "../stylesheets/manageApi.scss";

export default function ManageApi() {
  const [activeManageComponent, setActiveManageComponent] = useState(0);
  const { websiteID } = useSelector((state) => state.generalData);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [selectMultipleRowData, setSelectMultipleRowData] = useState([]);
  const [renderApi, setRenderApi] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await getManageApi(websiteID);
      if (response) {
        setData(response.data.result);
      }
      setLoading(false);
    };
    fetchData();
  }, [websiteID, renderApi]);

  useEffect(() => {
    // Retrieve the activeManageComponent value from storage when the component mounts
    const storedActiveComponent = storage.getItem("activeManageComponent");
    if (storedActiveComponent) {
      setActiveManageComponent(parseInt(storedActiveComponent, 10));
    }
  }, []);

  useEffect(() => {
    // Store the activeManageComponent value in storage whenever it changes
    storage.setItem("activeManageComponent", activeManageComponent.toString());
  }, [activeManageComponent]);

  const handleChnageRender = () => {
    setRenderApi(!renderApi);
  };

  const handleMultipleDelete = () => {
    if (selectMultipleRowData.length === 0) {
      Toastr.error(t("ManageApi.deleteError"));
    } else {
      setActiveManageComponent(3);
    }
  };

  const renderSavedApis = () => {
    if (loading) {
      return <Loader />;
    }
    if (data.length === 0) {
      return <NoApiPlaceholder />;
    }
    return (
      <SavedApis
        data={data}
        setActiveManageComponent={setActiveManageComponent}
        setSelectedRowData={setSelectedRowData}
        setSelectMultipleRowData={setSelectMultipleRowData}
      />
    );
  };

  const renderActiveComponent = () => {
    switch (activeManageComponent) {
      case 1:
        return (
          <div
            style={isMobile ? { width: "20px" } : {}}
            className="flex-center"
          >
            <AddApi
              setActiveManageComponent={setActiveManageComponent}
              handleChnageRender={handleChnageRender}
            />
          </div>
        );
      case 2:
        return (
          <div
            style={isMobile ? { width: "20px" } : {}}
            className="flex-center"
          >
            <EditApi
              setActiveManageComponent={setActiveManageComponent}
              selectedRowData={selectedRowData}
              handleChnageRender={handleChnageRender}
            />
          </div>
        );
      case 3:
        return (
          <div
            style={isMobile ? { width: "20px" } : {}}
            // className="flex-center"
          >
            <DeleteApi
              setActiveManageComponent={setActiveManageComponent}
              selectedRowData={selectedRowData}
              selectMultipleRowData={selectMultipleRowData}
              handleChnageRender={handleChnageRender}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <section className="manage-api-section">
      <AuthorizedHeader />
      <div className="container">
        <div className="flex-between">
          <h3 className="modules-heading">{t("ManageApi.title")}</h3>
          <div className="flex">
            {/* <img
              className="delete-icon"
              src={DeleteIcon}
              alt="delete-icon"
              onClick={handleMultipleDelete}
            /> */}
            <button
              className={`delete-multiple ${
                isMobile ? "backgroundSizeMobile" : "backgroundSize"
              }`}
              type="button"
              onClick={handleMultipleDelete}
            />
            <ButtonDark
              buttonText={t("ManageApi.addApi")}
              icon={AddIcon}
              onClick={() => setActiveManageComponent(1)}
            />
          </div>
        </div>
        <div className="component-rendered">{renderSavedApis()}</div>
      </div>
      {activeManageComponent > 0 && <div className="overlay" />}
      <div className="renderedActiveComponent">{renderActiveComponent()}</div>
    </section>
  );
}
